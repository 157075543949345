import { createStore } from 'vuex';

import authData from "./authData";
import customers from "./customers";
import parpols from "./parpols";
import propinsis from "./propinsis";
import templates from "./templates";
import surattemplates from "./surattemplates";
import reminders from "./reminders";
import dpts from "./dpts";
import canvass from "./canvass";
import profiles from "./profiles";
import subscribes from "./subscribes";
import commands from "./commands";
import saksisuaras from "./saksisuaras";
import users from "./users";
import tps from "./tps";


// Create store
export default createStore({
  modules: {
    authData,
    customers,
    parpols,
    propinsis,
    templates,
    surattemplates,
    reminders,
    dpts,
    canvass,
    profiles,
    subscribes,
    commands,
    saksisuaras,
    users,
    tps
  }
});
