const commands = {
    namespaced: true,
    state: {
      // ACTIVE
      activeCommand: [],
  
      // LIST
      listCommands: [],
  
      // SHOW
      showFormCommand: false,
    },
    getters: {
      getShowFormCommand: (state) => {
        return state.showFormCommand;
      },
      getActiveCommand: (state) => {
        return state.activeCommand;
      },
      getActiveCommandManage: (state) => {
        return state.activeCommand;
      },
      getListCommand: (state) => {
        return state.listCommand;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormCommand(state, value) {
        state.showFormCommand = value;
      },
      setActiveCommandManage(state, value) {
        state.activeCommand = value;
      },  
      setListCommand(state, value) {
        state.listCommand = value;
      },  
     },
    actions: { }
  }
  
  export default commands;