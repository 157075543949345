<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-group col-md-6">
                                    <div class="form-row">
                                        <div class="form-group col-md-4">
                                            <label>Periode</label>
                                            <select class="form-control" placeholder="Periode" v-model="form.periode">
                                                <option value="">Choose...</option>
                                                <option
                                                    v-for="list in listperiode"
                                                    :key="list.id"
                                                    :value="list.id"
                                                    >{{ list.id }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label>Waktu</label>
                                            <input type="text" class="form-control" placeholder="Waktu" v-model="form.waktu">
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name: 'Reminder',
    data() {
        return {
            idori:"",
            listperiode: [

            {
                    id: "Bulan"
                },
                {
                    id: "Minggu"
                },
                {
                    id: "Jam"
                },
                {
                    id: "Menit"
                },
            ],
            form: {
                periode: "",
                waktu: ""
            },
            url: "/graphql",
            payload: ""  
        }
    },
    computed: {
        activeReminder: {
            get() {
                return this.$store.getters['reminders/getActiveReminderManage'];
            },
            set(value) {
                this.$store.commit('reminders/setActiveReminderManage', value);
            }
        },
        isFormReminderActive: {
            get() {
                return this.$store.getters['reminders/getShowFormReminder'];
            },
            set(value) {
                this.$store.commit('reminders/setShowFormReminder', value);
            }
        },
        listReminder: {
            get() {
                return this.$store.getters['reminders/getListReminder'];
            },
            set(value) {
                this.$store.commit('reminders/setListReminder', value);
            }
        },
    },
    methods: {
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            const id = vm.form.periode + "-" + vm.form.waktu;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateReminder(id : "${vm.idori}"
                            input: {
                                id: "${id}"
                            }
                        )
                        {
                            id
                            status
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setReminder (input : {
                            id: "${id}"
                        })
                        {
                            id
                            status
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.errors) {
                    const msg = (response.data.errors[0].message=="Validation error") ? "Data Duplicate" : JSON.stringify(response.data.errors);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: msg,
                    });
                } else {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        }     
    },
    computed: {
        activeReminder: {
            get() {
                return this.$store.getters['reminders/getActiveReminderManage'];
            },
            set(value) {
                this.$store.commit('reminders/setActiveReminderManage', value);
            }
        },
        isFormReminderActive: {
            get() {
                return this.$store.getters['reminders/getShowFormReminder'];
            },
            set(value) {
                this.$store.commit('reminders/setShowFormReminder', value);
            }
        },
        listReminder: {
            get() {
                return this.$store.getters['reminders/getListReminder'];
            },
            set(value) {
                this.$store.commit('reminders/setListReminder', value);
            }
        },
    },
    watch: {
        activeReminder: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                vm.idori = value.id;
                const [periode,waktu] = value.id.split("-");
                vm.form.periode = periode;
                vm.form.waktu = waktu;
            }
        }
    },
    created() {
      const vm = this;
    },    
};
</script>