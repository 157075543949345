<template>
    <div class="form-group col-md-6">
        <label>Agama</label>
        <select id="inputState" class="form-control" 
            v-model="agamaId"
            @change="onChange($event)"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listAgama"
                :key="list.id"
                :value="list.id"
                >{{ list.id }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'AgamaField',
    props:[
        "agamaIdProp"
    ],
    data() {
        return {
            listAgama:"",
            agamaId:"",
            disableInput: true,
        }
    },
    methods: {
        onChange(event) {
            const agamaId = event.target.value;
            bus.emit("openAgamaCustomer",agamaId);
        },
        initData() {
            let vm = this;
            vm.getData();            
            vm.agamaId = vm.agamaId;
        },
        getData() {
            let vm = this;
            const payload = {
                query : `query {
                    getAgamas
                    {
                        id
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listAgama = response.data.data.getAgamas;
                }
            })
            .catch((error) => {
                console.log("error-agamaField",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        vm.agamaId = vm.agamaIdProp;
    },
    created() {
        let vm = this; 
        vm.initData();
        bus.on("openAgamaField", (agamaId) => {
            vm.agamaId = agamaId;
        });
    },
}
</script>