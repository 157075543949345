<template>
    <div class="form-group col-md-6">
        <label>Kabupaten</label>
        <select id="inputState" class="form-control" 
            v-model="kabupatenId"
            @change="onChange($event)"
            :disabled="disable"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listKabupaten"
                :key="list.id"
                :value="list.id"
                >{{ list.nama }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'KabupatenField',
    props:[
    "kabId",
    "propId",
    "disable"
  ],
    data() {
        return {
            listKabupaten:"",
            kabupatenId:"",
            disableInput: true,
        }
    },
    methods: {
        onChange(event) {
            const kabupatenId = event.target.value;
            const senddata = {
                desaId : "",
                kecamatanId : "",
                kabupatenId : kabupatenId,
                propinsiId : ""
            }
            bus.emit("openUser",senddata);
            bus.emit("openSaksiSuara",senddata);
            bus.emit("openOrg",senddata);
            bus.emit("openTemplateSurat",senddata);
            bus.emit("openKecamatanField",senddata);
            bus.emit("openDesaField",-1);
        },
        initData() {
            let vm = this;
            const kabId = vm.kabId;
            const prodId = vm.propId;
            vm.kabupatenId = kabId;
            vm.getData(prodId);
        },
        getData(propinsiId) {
            let vm = this;
            const payload = {
                query : `query {
                    getKabupatens(
                        propinsiId : ${propinsiId}
                    )
                    {
                        id
                        nama
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listKabupaten = response.data.data.getKabupatens;
                }
            })
            .catch((error) => {
                console.log("error",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        if(!!vm.propId && !!vm.kabId) {
            vm.propinsiId = vm.propId;
            vm.kabupatenId = vm.kabId;
        }
    },
    created() {
        let vm = this; 
        vm.initData();
        bus.on("openKabupatenField", (data) => {
            vm.kabupatenId = data.kabupatenId;
            vm.getData(data.propinsiId);
        });
    },
}
</script>