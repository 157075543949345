<template>
    <!--*******************
        Preloader start
    ********************-->
	<Loader></Loader>
    <!--*******************
        Preloader end
    ********************-->

    <!--**********************************
        Main wrapper start
    ***********************************-->
    <div id="main-wrapper">

        <!--**********************************
            Nav header start
        ***********************************-->
        <div class="nav-header">
            <a href="/" class="brand-logo">
                <img class="logo-abbr" src="/template/images/logo.png" alt="">
                <img src="/template/images/logo-text.png" alt="">
            </a>

            <div class="nav-control">
                <div class="hamburger">
                    <span class="line"></span><span class="line"></span><span class="line"></span>
                </div>
            </div>
        </div>
        <!--**********************************
            Nav header end
        ***********************************-->

		
		<!--**********************************
            Header start
        ***********************************-->
		<Header title="Subscribe"></Header>
        <!--**********************************
            Header end ti-comment-alt
        ***********************************-->

        <!--**********************************
            Sidebar start
        ***********************************-->
		<Sidebar></Sidebar>
        <!--**********************************
            Sidebar end
        ***********************************-->
		
		<!--**********************************
            Content body start
        ***********************************-->
        <div class="content-body">
			<SubscribeList v-if="!isSubscribeActive"></SubscribeList>
			<Subscribe v-else></Subscribe>
        </div>
        <!--**********************************
            Content body end
        ***********************************-->

        <!--**********************************
            Footer start
        ***********************************-->
		<Footer></Footer>
    </div>    
</template>
<script>
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import SubscribeList from '../../components/SubscribeList';
import Subscribe from '../../components/Subscribe';
import Loader from '../../components/Loader';
export default {
	components: { Header, Sidebar, Footer, Loader, SubscribeList, Subscribe },
    name: 'PageSubscribe',
    props: [],
    data() {
        return {
        }
    },
    computed: {
        isSubscribeActive: {
            get() {
                return this.$store.getters['subscribes/getShowFormSubscribe'];
            },
            set(value) {
                this.$store.commit('subscribes/setShowFormSubscribe', value);
            }
        }
    },
}
</script>
