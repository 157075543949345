<template>
    <!--*******************
        Preloader start
    ********************-->
	<Loader></Loader>
    <!--*******************
        Preloader end
    ********************-->

    <!--**********************************
        Main wrapper start
    ***********************************-->
    <div id="main-wrapper">

        <!--**********************************
            Nav header start
        ***********************************-->
        <div class="nav-header">
            <a href="index.html" class="brand-logo">
                <img class="logo-abbr" src="/template/images/logo.png" alt="">
                <img src="/template/images/logo-text.png" alt="">
            </a>

            <div class="nav-control">
                <div class="hamburger">
                    <span class="line"></span><span class="line"></span><span class="line"></span>
                </div>
            </div>
        </div>
        <!--**********************************
            Nav header end
        ***********************************-->

		
		<!--**********************************
            Header start
        ***********************************-->
		<Header title="Partai Politik"></Header>
        <!--**********************************
            Header end ti-comment-alt
        ***********************************-->

        <!--**********************************
            Sidebar start
        ***********************************-->
		<Sidebar></Sidebar>
        <!--**********************************
            Sidebar end
        ***********************************-->
		
		<!--**********************************
            Content body start
        ***********************************-->
        <div class="content-body">
			<ParpolList v-if="!isFormParpolActive"></ParpolList>
			<Parpol v-else></Parpol>
        </div>
        <!--**********************************
            Content body end
        ***********************************-->

        <!--**********************************
            Footer start
        ***********************************-->
		<Footer></Footer>
    </div>    
</template>
<script>
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ParpolList from '../../components/ParpolList';
import Parpol from '../../components/Parpol';
import Loader from '../../components/Loader';
export default {
	components: { Header, Sidebar, Footer, Loader, ParpolList, Parpol },
    name: 'PageParpol',
    props: [],
    data() {
        return {
        }
    },
    computed: {
        isFormParpolActive: {
            get() {
                return this.$store.getters['parpols/getShowFormParpol'];
            },
            set(value) {
                this.$store.commit('parpols/setShowFormParpol', value);
            }
        }
    },
}
</script>
