<template>
    <div class="footer">
        <div class="copyright">
            <p>Copyright © Bot Ahli Admin Dashboard 2023</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer'
};
</script>