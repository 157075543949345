<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            NIK
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="1344131345512" v-model="form.nama">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Alamat
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Jln Melati no 10" v-model="form.contact">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            No KK
                                        </label>
                                        <input type="text" class="form-control" placeholder="9882771244555" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Tempat Lahir/Tgl Lahir
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-4">
                                                <input type="text" class="form-control" placeholder="Jakarta" v-model="form.alamat">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <input type="text" class="form-control" placeholder="31/12/2023" v-model="form.alamat">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Status Pernikahan
                                        </label>
                                        <div class="form-group mb-0">
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Belum Menikah</label>
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Menikah</label>
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Cerai</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Jenis Kelamin
                                        </label>
                                        <div class="form-group mb-0">
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Laki-laki</label>
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Perempuan</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            RT/RW
                                        </label>
                                        <div class="form-row">
                                            <div class="form-group col-md-3">
                                                <input type="text" class="form-control" placeholder="001" v-model="form.alamat">
                                            </div>
                                            <div class="form-group col-md-3">
                                                <input type="text" class="form-control" placeholder="003" v-model="form.alamat">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Disabilitas
                                        </label>
                                        <div class="form-group mb-0">
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Ya</label>
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Tidak</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            EKTP
                                        </label>
                                        <div class="form-group mb-0">
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Ya</label>
                                            <label class="radio-inline mr-3"><input type="radio" name="optradio"> Tidak</label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Tahapan
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Rank
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Sumber
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            TPS
                                        </label>
                                        <input type="text" class="form-control" placeholder="Jl Melati no 10" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            DPD
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Kecamatan
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Kelurahan
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Keterangan
                                        </label>
                                        <input type="text" class="form-control" v-model="form.alamat">
                                    </div>
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name: 'DaftarPemilihTetap',
    data() {
        return {
            idori:"",
            profesi:"",
            listKabupaten:"",
            listKecamatan:"",
            listDesa:"",
            form: {
                nama: "",
                contact: "",
                alamat: "",
                propinsiId: null,
                kabupatenId: null,
                kecamatanId: null,
                desaId: null,
                visi: "",
                misi: "",
                ig: "",
                tiktok: "",
                youtube: "",
                fb: "",
                website: "",
                biodata: ""
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        getProfile() {
            const vm = this;
            if(localStorage.getItem("profile")) {
                const profile  = JSON.parse(localStorage.getItem("profile"));
                vm.profesi  = profile.profesi;
            }
            else {
                vm.profesi  = null;
            }
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updatePemilih(id : "${vm.idori}"
                            input: {
                                nama: "${form.nama}"
                                alamat: "${form.alamat}"
                                contact: "${form.contact}"
                                propinsiId: "${form.propinsiId}"
                                kabupatenId: "${form.kabupatenId}"
                                kecamatanId: "${form.kecamatanId}"
                                desaId: "${form.desaId}"
                                visi: "${form.visi}"
                                misi: "${form.misi}"
                                ig: "${form.ig}"
                                tiktok: "${form.tiktok}"
                                youtube: "${form.youtube}"
                                fb: "${form.fb}"
                                website: "${form.website}"
                                biodata: "${form.biodata}"
                            }
                        )
                        {
                            nama
                            alamat
                            contact
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setPemilih (input : {
                            nama: "${form.nama}"
                            alamat: "${form.alamat}"
                            contact: "${form.contact}"
                            propinsiId: "${form.propinsiId}"
                            kabupatenId: "${form.kabupatenId}"
                            kecamatanId: "${form.kecamatanId}"
                            desaId: "${form.desaId}"
                            visi: "${form.visi}"
                            misi: "${form.misi}"
                            ig: "${form.ig}"
                            tiktok: "${form.tiktok}"
                            youtube: "${form.youtube}"
                            fb: "${form.fb}"
                            website: "${form.website}"
                            biodata: "${form.biodata}"
                        })
                        {
                            nama
                            alamat
                            contact
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (_.isEmpty(vm.form.contact)) {
                err_message += "- Contact Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
    },
    computed: {
        activeDpt: {
            get() {
                return this.$store.getters['dpts/getActiveDptManage'];
            },
            set(value) {
                this.$store.commit('dpts/setActiveDptManage', value);
            }
        },
        isFormDptActive: {
            get() {
                return this.$store.getters['dpts/getShowFormDpt'];
            },
            set(value) {
                this.$store.commit('dpts/setShowFormDpt', value);
            }
        },
        listDpt: {
            get() {
                return this.$store.getters['dpts/getListDpt'];
            },
            set(value) {
                this.$store.commit('dpts/setListDpt', value);
            }
        },
    },
    watch: {
        activeDpt: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
    },    
};
</script>