<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            Nama
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Sancoko" v-model="form.nama">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            NIK
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="12313131313" v-model="form.nik">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            User Name (Auto Generated)
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control"  v-model="form.username" :disabled="usernameDisabled">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            No HP
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="628127166155" v-model="form.nohp" :disabled="nohpDisabled" @input="autoFillCountryCode">
                                    </div>
                                    <PropinsiField :propId="form.propId"></PropinsiField> 
                                    <KabupatenField :propId="form.propId" :kabId="form.kabId"></KabupatenField> 
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { bus } from "../main";

import PropinsiField from './field/PropinsiField';
import KabupatenField from './field/KabupatenField';
import KecamatanField from './field/KecamatanField';
import DesaField from './field/DesaField';

export default {
    components: { PropinsiField, KabupatenField, KecamatanField, DesaField },
    name: 'User',
    data() {
        return {
            idori:"",
            usernameDisabled: true,            
            nohpDisabled: false,            
            form: {
                nama: "",
                nik: "",
                nohp: "",
                username: "",
                propId: null,
                kabId: null,
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        autoFillCountryCode() {
            const vm = this;
            if (!vm.form.nohp.startsWith('62')) {
                if(vm.form.nohp==0) {
                    vm.form.nohp = '62';
                }
                else {
                    vm.form.nohp = '62'+vm.form.nohp;
                }
            }
            else if(vm.form.nohp=="620") {
                vm.form.nohp = '62'+vm.form.nohp;
            }
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateUser(id : "${vm.idori}"
                            input: {
                                nama: "${form.nama}"
                                nik: "${form.nik}"
                                propinsiId: "${form.propId}"
                                kabupatenId: "${form.kabId}"
                                roleId: "Koordinator"
                                username: "${form.nohp}"                            
                            }
                        )
                        {
                            id
                            email
                            status
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setUser (input : {
                            nama: "${form.nama}"
                            nik: "${form.nik}"
                            contact: "${form.nohp}"
                            propinsiId: "${form.propId}"
                            kabupatenId: "${form.kabId}"
                            roleId: "Koordinator"
                            username: "${form.nohp}"                            
                        })
                        {
                            id
                            email
                            status
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if(response.data.errors) {
                    const msgerror = response.data.errors;
                    let msg = msgerror[0].message;
                    const regex = /Validation error/gi;
                    const matches = msgerror[0].message.match(regex);
                    if (matches) {
                        msg = `Data Wilayah atau Data Contact sudah digunakan`;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                }
                else {

                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (_.isEmpty(vm.form.nohp)) {
                err_message += "- No HP Kosong<br>";
            }
            if (_.isEmpty(vm.form.nik)) {
                err_message += "- NIK Kosong<br>";
            }
            if (_.isEmpty(vm.form.propId)) {
                err_message += "- Propinsi Kosong<br>";
            }
            if (_.isEmpty(vm.form.kabId)) {
                err_message += "- Kabupaten Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
    },
    computed: {
        activeUser: {
            get() {
                return this.$store.getters['users/getActiveUserManage'];
            },
            set(value) {
                this.$store.commit('users/setActiveUserManage', value);
            }
        },
        isFormUserActive: {
            get() {
                return this.$store.getters['users/getShowUser'];
            },
            set(value) {
                this.$store.commit('users/setShowFormUser', value);
            }
        },
        listUser: {
            get() {
                return this.$store.getters['users/getListUser'];
            },
            set(value) {
                this.$store.commit('users/setListUser', value);
            }
        },
    },
    watch: {
        activeUser: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                console.log("value",JSON.stringify(value));
                let datacontact = JSON.parse(value.contact);
                console.log("contact1",datacontact);
                datacontact = JSON.parse(datacontact);
                console.log("contact2",datacontact);
                vm.idori                = value.id;
                vm.form.nama            = value.nama;              
                vm.form.nohp            = `${datacontact[0]}`;              
                vm.form.nik             = value.nik;
                vm.form.propId          = (value.propinsiId==null) ? null : value.propinsiId;              
                vm.form.kabId           = (value.kabupatenId==null) ? null : value.kabupatenId;              
                const datasend = {
                    desaId : "",
                    kecamatanId : "",
                    kabupatenId : vm.form.kabId,
                    propinsiId : vm.form.propId
                }
                bus.emit("openPropinsiField",datasend);
                bus.emit("openKabupatenField",datasend);
                vm.nohpDisabled = true;
            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
      bus.on("openUser", (data) => {
        if(!!data.propinsiId && data.propinsiId!="") vm.form.propId = data.propinsiId;
        if(!!data.kabupatenId && data.kabupatenId!="") vm.form.kabId = data.kabupatenId;
      });

    },    
};
</script>