const dpts = {
    namespaced: true,
    state: {
      // ACTIVE
      activeDpt: [],
  
      // LIST
      listDpts: [],
  
      // SHOW
      showFormDpt: false,
    },
    getters: {
      getShowFormDpt: (state) => {
        return state.showFormDpt;
      },
      getActiveDpt: (state) => {
        return state.activeDpt;
      },
      getActiveDptManage: (state) => {
        return state.activeDpt;
      },
      getListDpt: (state) => {
        return state.listDpt;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormDpt(state, value) {
        state.showFormDpt = value;
      },
      setActiveDptManage(state, value) {
        state.activeDpt = value;
      },  
      setListDpt(state, value) {
        state.listDpt = value;
      },  
     },
    actions: { }
  }
  
  export default dpts;