const templates = {
    namespaced: true,
    state: {
      // ACTIVE
      activeTemplate: [],
  
      // LIST
      listTemplates: [],
  
      // SHOW
      showFormTemplate: false,
    },
    getters: {
      getShowFormTemplate: (state) => {
        return state.showFormTemplate;
      },
      getActiveTemplate: (state) => {
        return state.activeTemplate;
      },
      getActiveTemplateManage: (state) => {
        return state.activeTemplate;
      },
      getListTemplate: (state) => {
        return state.listTemplate;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormTemplate(state, value) {
        state.showFormTemplate = value;
      },
      setActiveTemplateManage(state, value) {
        state.activeTemplate = value;
      },  
      setListTemplate(state, value) {
        state.listTemplate = value;
      },  
     },
    actions: { }
  }
  
  export default templates;