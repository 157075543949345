<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                     <PropinsiField :propId="form.propId"></PropinsiField> 
                                     <KabupatenField :propId="form.propId" :kabId="form.kabId"></KabupatenField> 
                                </div>
                                <div class="form-row">
                                    <KecamatanField :kabId="form.kabId" :kecId="form.kecId"></KecamatanField> 
                                    <DesaField :kecId="form.kecId" :desaId="form.desaId"></DesaField> 
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Jenis</label>
                                        <select id="inputState" class="form-control default-select" v-model="form.jenissuratId">
                                            <option value="">Choose...</option>
                                            <option
                                                v-for="list in listjenissurat"
                                                :key="list.id"
                                                :value="list.id"
                                                >{{ list.id }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Nama File
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="nama file" v-model="form.namafile">                                        
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Command</label>
                                        <select id="inputState" class="form-control default-select" v-model="form.command">
                                            <option value="">Choose...</option>
                                            <option
                                                v-for="list in listcommand"
                                                :key="list.id"
                                                :value="list.id"
                                                >{{ list.id }} ({{ list.ket }})
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Payload
                                            <span class="text-danger">*</span>
                                        </label>
                                        <textarea class="form-control" rows="5" cols="100" v-model="form.payload"></textarea>
                                    </div>
                                </div>                                
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            Alamat
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Jl Melati no 10" v-model="form.alamat">
                                    </div>
                                    <FileSuratField></FileSuratField>
                                </div>

                                <div class="col-md-12 text-right">
                                    <a href="#" class="btn btn-outline-success" variant="outline-success" data-toggle="modal" data-target="#data-detail" @click="showmodal(form)">Preview</a>                                    
                                </div>
                                <br/>

                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
            <TemplateSuratPreviewModal></TemplateSuratPreviewModal>
        </div>
    </div>
</template>
<script>
import { bus } from "../main";

import { jenissurat, kommand } from '../helpers/loaddata';
import FileSuratField from './field/FileSuratField';
import PropinsiField from './field/PropinsiField';
import KabupatenField from './field/KabupatenField';
import KecamatanField from './field/KecamatanField';
import DesaField from './field/DesaField';
import TemplateSuratPreviewModal from './modal/TemplateSuratPreviewModal';

export default {
    components: { TemplateSuratPreviewModal, FileSuratField, PropinsiField, KabupatenField, KecamatanField, DesaField },
    name: 'Template',
    data() {
        return {
            idori:"",
            listjenissurat:"",
            listcommand:"",
            previewMedia: [],
            deletedMedia: [],
            fileupload:[],
            isFileUpload:false,
            form: {
                propId: "",
                kabId: "",
                kecId: "",
                desaId: "",
                payload: "",
                command: "",
                alamat: "",
                jenissuratId: "",
                file: null,
                namafile: null,
                path:null
            },
            url: "/api/customersurat/",
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        initData() {
            let vm = this;
            if (localStorage.getItem("jenissurat")==null) {
                jenissurat();
            }
            if (localStorage.getItem("kommand")==null) {
                kommand();
            }
            vm.listjenissurat = JSON.parse(localStorage.getItem("jenissurat"));
            vm.listcommand = JSON.parse(localStorage.getItem("kommand"));
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.file)) {
                err_message += "- File Kosong<br>";
            }
            if (_.isEmpty(vm.form.jenissuratId)) {
                err_message += "- Jenis Kosong<br>";
            }
            if (_.isEmpty(vm.form.alamat)) {
                err_message += "- Alamat Kosong<br>";
            }
            if (_.isEmpty(vm.form.payload)) {
                err_message += "- Payload Kosong<br>";
            }
            if (_.isEmpty(vm.form.command)) {
                err_message += "- Command Kosong<br>";
            }
            if (_.isEmpty(vm.form.namafile)) {
                err_message += "- Nama File Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
        submit(form) {
            let vm = this;
            let vmethod;
            let dataurl;
            if(vm.idori!="") {
                vmethod = "PUT";
                dataurl = vm.url + vm.idori;
            }
            else {
                vmethod = "POST";
                dataurl = vm.url;
            }
            let formData = new FormData();
            formData.append('namafile', form.namafile);
            formData.append('jenissuratId', form.jenissuratId);
            formData.append('propinsiId', form.propId);
            formData.append('kabupatenId', form.kabId);
            formData.append('kecamatanId', form.kecId);
            formData.append('desaId', form.desaId);
            formData.append('payload', form.payload);
            formData.append('command', form.command);
            formData.append('alamat', form.alamat);
            if(form.file!="-") {
                formData.append('files', form.file);
            }
            else {
                formData.append('files', null);
            }
            const config = {
                method: vmethod,
                headers: {
                    'Content-Type': 'multipart/form-data'                    
                },
                url: dataurl,
                data : formData
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        showmodal(data) {
            const file = [
                {
                    filename: data.path
                }
            ]
            let senddata = {
                file: JSON.stringify(file)
            }
            bus.emit("openTemplateSuratPreviewModal",senddata);
        }
    },
    computed: {
        activeSuratTemplate: {
            get() {
                return this.$store.getters['surattemplates/getActiveSuratTemplateManage'];
            },
            set(value) {
                this.$store.commit('surattemplates/setActiveSuratTemplateManage', value);
            }
        },
        isFormSuratTemplateActive: {
            get() {
                return this.$store.getters['surattemplates/getShowFormSuratTemplate'];
            },
            set(value) {
                this.$store.commit('surattemplates/setShowFormSuratTemplate', value);
            }
        },
        listSuratTemplate: {
            get() {
                return this.$store.getters['surattemplates/getListTSuratemplate'];
            },
            set(value) {
                this.$store.commit('surattemplates/setListSuratTemplate', value);
            }
        },
    },
    watch: {
        activeSuratTemplate: {
            immediate: true,
            handler(value) {
                let vm = this;
                if(!value) return;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                const datafile = JSON.parse(value.file);
                const filedata =  datafile[0].filename.split(".");
                console.log("value-payload",JSON.parse(value.payload));                
                const datapayload = JSON.parse(value.payload);
                const command = datapayload.command;
                const payload = datapayload.payload;
                vm.idori                = value.id;
                vm.form.jenissuratId    = value.jenissuratId;
                vm.form.namafile        = filedata[0];
                vm.form.alamat          = value.alamat;
                vm.form.payload         = payload;
                vm.form.command         = command;
                vm.form.propId          = value.propinsiId;              
                vm.form.kabId           = value.kabupatenId;              
                vm.form.kecId           = value.kecamatanId;              
                vm.form.desaId          = value.desaId;
                vm.form.path            = datafile[0].filename;
                vm.form.file            = "-";   
            }
        }
    },
    mounted() {
      let vm = this;
    },
    created() {
      const vm = this;
      vm.initData();
      bus.on("openTemplateSurat", (data) => {
        console.log("data ",data);
        if(!!data.propinsiId && data.propinsiId!="") vm.form.propId = data.propinsiId;
        if(!!data.kabupatenId && data.kabupatenId!="") vm.form.kabId = data.kabupatenId;
        if(!!data.kecamatanId && data.kecamatanId!="") vm.form.kecId = data.kecamatanId;
        if(!!data.desaId && data.desaId!="") vm.form.desaId = data.desaId;
        if(!!data.file && data.file!="") vm.form.file = data.file;
      });
    },    
};
</script>