<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <Customer></Customer>
        </div>
    </div>
</template>
<script>
import { bus } from "../main";
import { parseJwt, findMatch } from '../helpers/global';
import Customer from '../components/Customer';

export default {
	components: { Customer },
    name: 'Profile',
    data() {
        return {
            idori:"",
            url: "/graphql",
            payload: "",
            profile: ""
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        getProfile() {
            const vm = this;
            const token  = localStorage.getItem("token") != null ? JSON.parse(localStorage.getItem("token")) : null;
            const userId = parseJwt(token)['user'];
            const payload = {
                query : `query {
                    getCustomer(userId:"${userId}")
                    {
                        id
                        nama
                        userId
                        alamat
                        biodata
                        tiktok
                        youtube
                        fb
                        ig
                        website
                        agamaId
                        notelp
                        status
                        foto
                        contact
                        User{
                            email
                        }
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (!!response.data.errors) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
                else {
                    vm.profile = response.data.data.getCustomer;
                    vm.activeCustomer = vm.profile;
                    bus.emit("openCustomer",vm.profile);
                }
            })
            .catch((error) => {
                const reserror = findMatch(error.message,"401");
                if(reserror) {
                    localStorage.removeItem("token");            
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: "Token Expired",
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.href = `/`;
                    });
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: error.message,
                    });
                }
            });
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        initData() {
            let vm = this;
            vm.getProfile();
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (_.isEmpty(vm.form.contact)) {
                err_message += "- Contact Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
    },
    computed: {
        activeCustomer: {
            get() {
                return this.$store.getters['customers/getActiveCustomerManage'];
            },
            set(value) {
                this.$store.commit('customers/setActiveCustomerManage', value);
            }
        },
        activeProfile: {
            get() {
                return this.$store.getters['profiles/getActiveProfileManage'];
            },
            set(value) {
                this.$store.commit('profiles/setActiveProfileManage', value);
            }
        },
        isFormProfileActive: {
            get() {
                return this.$store.getters['profiles/getShowFormProfile'];
            },
            set(value) {
                this.$store.commit('profiles/setShowFormProfile', value);
            }
        },
        listProfile: {
            get() {
                return this.$store.getters['profiles/getListProfile'];
            },
            set(value) {
                this.$store.commit('profiles/setListProfile', value);
            }
        },
    },
    watch: {
        activeProfile: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
    },    
};
</script>