const tps = {
    namespaced: true,
    state: {
      // ACTIVE
      activeTp: [],
  
      // LIST
      listTps: [],
  
      // SHOW
      showFormTp: false,
    },
    getters: {
      getShowFormTp: (state) => {
        return state.showFormTp;
      },
      getActiveTp: (state) => {
        return state.activeTp;
      },
      getActiveTpManage: (state) => {
        return state.activeTp;
      },
      getListTp: (state) => {
        return state.listTp;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormTp(state, value) {
        state.showFormTp = value;
      },
      setActiveTpManage(state, value) {
        state.activeTp = value;
      },  
      setListTp(state, value) {
        state.listTp = value;
      },  
     },
    actions: { }
  }
  
  export default tps;