<template>
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-6">
					
					<div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
									<div class="text-center mb-3">
										<a href="index.html"><img src="../assets/images/logo-full.png" alt=""></a>
									</div>
                                    <h4 class="text-center mb-4 text-white">Onboarding</h4>
                                    <form action="index.html">
                                        <div class="form-group">
                                            <label class="mb-1 text-white"><strong>Apakah Sudah Pernah Onboard Via WA?</strong></label>
                                            <div class="form-group mb-0">
                                                <label class="radio-inline mr-3 text-white"><input type="radio" name="optradio" value="yes" v-model="showTextBox"> Ya</label>
                                                <label class="radio-inline mr-3 text-white"><input type="radio" name="optradio" value="no" v-model="showTextBox"> Tidak</label>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="showTextBox === 'yes'">
                                            <label class="mb-1 text-white"><strong>Nama</strong></label>
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="text-center mt-4">
                                            <button type="submit" class="btn bg-white text-primary btn-block">Sign me up</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Register',
    data() {
        return {
            showTextBox: '',
            textBoxValue: '',
        };
    },    
}
</script>