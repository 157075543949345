<template>
    <div class="modal fade none-border" ref="eventmodal">
        <div class="modal-dialog">
            <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title"><strong>Add Agenda</strong></h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="control-label">Tanggal:</label>
                                <input type="text" class="form-control" placeholder="2023-09-25" v-model="form.tanggal" :disabled="disableInput">
                            </div>
                            <div class="col-md-3">
                                <label class="control-label">Waktu:</label>
                                <input type="text" maxlength="5" class="form-control" @input="formatTime" placeholder="hh:mm" v-model="form.waktu">
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Kategori:</label>
                                <select id="inputState" class="form-control" v-model="form.categoryId">
                                    <option value="">Choose...</option>
                                    <option
                                        v-for="list in listCategory"
                                        :key="list.id + '_' + list.color"
                                        :value="list.id"
                                        >{{ list.id }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label class="control-label">Agenda:</label>
                                <input type="text" class="form-control" placeholder="Rapat dengan client" v-model="form.nama" maxlength="87">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-5">
                                    <button type="button" class="btn btn-danger" @click="deleteForm(idori)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-remove color-info"></i>
                                        </span>Delete
                                    </button>
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-success save-event waves-effect waves-light" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import { bus } from "../../main";
import { formatHumanToDateUnix, formatUTCDateToLocal } from '../../helpers/global';
export default {
    name: 'CalendarEventModal',
    data() {
        return {
            idori:"",
            listCategory:"",
            form: {
                tanggal: "",
                waktu: "",
                nama: "",
                categoryId: ""
            },
            disableInput: true,
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.tanggal)) {
                err_message += "- Tanggal Kosong<br>";
            }
            if (_.isEmpty(vm.form.waktu)) {
                err_message += "- Waktu Kosong<br>";
            }
            if (_.isEmpty(vm.form.categoryId)) {
                err_message += "- Kategori Kosong<br>";
            }
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
        formatTime() {
            this.form.waktu = this.form.waktu.replace(/[^0-9]/g, '').slice(0, 4);
            if (this.form.waktu.length >= 2) {
                this.form.waktu = this.form.waktu.slice(0, 2) + ':' + this.form.waktu.slice(2);
            }            
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            let waktu = vm.form.tanggal + " " + vm.form.waktu + ":00";
            waktu = formatHumanToDateUnix(waktu);
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateAgenda(id : "${vm.idori}"
                            input: {
                                categoryId: "${form.categoryId}"
                                nama: "${form.nama}"
                                waktu: "${waktu}"
                            }
                        )
                        {
                            id
                            categoryId
                            nama
                            waktu
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setAgenda (input : {
                            categoryId: "${form.categoryId}"
                            nama: "${form.nama}"
                            waktu: "${waktu}"
                        })
                        {
                            id
                            categoryId
                            nama
                            waktu
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        deleteForm(id) {
            let vm = this;
            vm.payload = {
                query : 
                `
                    mutation {    
                        deleteAgenda(id : "${id}")
                        {
                            id
                        }
                    }                
                `
            }
            Swal.fire({
                    title: "Are you sure ?",
                    text: "You want to delete",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const config = {
                            method: "POST",
                            url: vm.url,
                            data : vm.payload
                        }
                        axiosKpapi(config)
                        .then((response) => {
                            if (response.data.data) {
                                Swal.fire({
                                icon: 'success',
                                title: 'Yeay...',
                                text: "Success",
                                confirmButtonText: 'Ok'
                                }).then((result) => {
                                    window.location.reload();
                                })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops!',
                                    text: JSON.stringify(response.data.errors),
                                });
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: error.message,
                            });
                        });
                    }
                });
        }
    },    
    created() {
        let vm = this; 
        bus.on("openCalendarEventModal", (data) => {
            const datatgl = JSON.parse(JSON.stringify(data.tanggal));
            const [tgl,waktu] = JSON.parse(formatUTCDateToLocal(datatgl,'yyyy-MM-dd HH:mm')).split(" ");
            if(data.categoryId=="bg-danger") vm.form.categoryId="High";
            else if(data.categoryId=="bg-warning") vm.form.categoryId="Medium";
            else vm.form.categoryId="Low"
            vm.idori = (data.id==undefined) ? "" : data.id;
            vm.form.tanggal = tgl;
            vm.form.waktu = waktu;
            vm.form.nama = data.title;
            vm.listCategory = data.category
            $(this.$refs.eventmodal).modal('show');
        });
    }
}
</script>