<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">                                
                                    <div class="form-group col-md-6">
                                        <label>
                                            Organisasi
                                            <span class="text-danger">*</span>
                                        </label>
                                        <select id="inputState" class="form-control" v-model="form.orgId">
                                            <option value="">Choose...</option>
                                            <option
                                                v-for="list in listOrganisasi"
                                                :key="list.id"
                                                :value="list.id"
                                                >{{ list.id }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            Kepala
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" id="val-kepala" placeholder="Kepala" v-model="form.kepala">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Wakil
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Wakil" v-model="form.wakil">
                                    </div>
                                </div>
                                <div class="form-row">
                                     <PropinsiField></PropinsiField> 
                                     <KabupatenField :kabId="form.kabId"></KabupatenField> 
                                </div>
                                <div class="form-row">
                                    <KecamatanField :kecId="form.kecId"></KecamatanField> 
                                    <DesaField :desaId="form.desaId"></DesaField> 
                                </div>
                                <div class="form-row">                                
                                    <div class="form-group col-md-6">
                                        <label>
                                            Kop Surat
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" @change="processFile1(index,$event)">
                                            <label class="custom-file-label">Choose file</label>												
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Stempel
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" @change="processFile2(index,$event)">
                                            <label class="custom-file-label">Choose file</label>												
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <div class="card mr-3 mb-3 w-12rem" v-for="(media, indexmedia) in previewMedia1" :key="indexmedia">
                                            <span 
                                                class="text-danger close btn btn-remove-media" 
                                                @click="removeMedia1(indexmedia, index)"
                                            ><i class="fa fa-times"></i></span>
                                            <img v-if="media.type == 'image'" class="card-img-top h-100px" :src="media.src" alt="image">
                                            <img v-else class="mx-auto avatar-50px" :src="'/images/'+media.type+'.png'" alt="media">
                                            <div class="card-footer text-muted">
                                                <a :href="media.src" target="_blank">{{ media.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="card mr-3 mb-3 w-12rem" v-for="(media, indexmedia) in previewMedia2" :key="indexmedia">
                                            <span 
                                                class="text-danger close btn btn-remove-media" 
                                                @click="removeMedia2(indexmedia, index)"
                                            ><i class="fa fa-times"></i></span>
                                            <img v-if="media.type == 'image'" class="card-img-top h-100px" :src="media.src" alt="image">
                                            <img v-else class="mx-auto avatar-50px" :src="'/images/'+media.type+'.png'" alt="media">
                                            <div class="card-footer text-muted">
                                                <a :href="media.src" target="_blank">{{ media.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">                                
                                    <div class="form-group col-md-6">
                                        <label>
                                            Tanda Tangan
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" id="file" class="custom-file-input" @change="processFile3(index,$event)">
                                            <label class="custom-file-label">Choose file</label>												
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <div class="card mr-3 mb-3 w-12rem" v-for="(media, indexmedia) in previewMedia3" :key="indexmedia">
                                            <span 
                                                class="text-danger close btn btn-remove-media" 
                                                @click="removeMedia3(indexmedia, index)"
                                            ><i class="fa fa-times"></i></span>
                                            <img v-if="media.type == 'image'" class="card-img-top h-100px" :src="media.src" alt="image">
                                            <img v-else class="mx-auto avatar-50px" :src="'/images/'+media.type+'.png'" alt="media">
                                            <div class="card-footer text-muted">
                                                <a :href="media.src" target="_blank">{{ media.name }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { bus } from "../main";

import { organisasi } from '../helpers/loaddata';
import PropinsiField from './field/PropinsiField';
import KabupatenField from './field/KabupatenField';
import KecamatanField from './field/KecamatanField';
import DesaField from './field/DesaField';

export default {
    components: { PropinsiField, KabupatenField, KecamatanField, DesaField },
    name: 'Organisasi',
    data() {
        return {
            idori:"",
            listOrganisasi:"",
            previewMedia1: [],
            deletedMedia1: [],
            fileupload1:[],
            isFileUpload1:false,
            previewMedia2: [],
            deletedMedia2: [],
            fileupload2:[],
            isFileUpload2:false,
            previewMedia3: [],
            deletedMedia3: [],
            fileupload3:[],
            isFileUpload3:false,
            form: {
                orgId: "",
                kepala: "",
                wakil: "",
                propId: "",
                kabId: "",
                kecId: "",
                desaId: "",
                file1: "",
                file2: "",
                file3: ""
            },
            payload: "",
            urlgraphql : "/graphql",
            url: "/api/customerorg/",
            urlimg: "/api/image/",
            fileUrl: process.env.VUE_APP_URL_BACKEND + "/api/image"
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            let vmethod;
            let dataurl;
            if(vm.idori!="") {
                vmethod = "PUT";
                dataurl = vm.url + vm.idori;
            }
            else {
                vmethod = "POST";
                dataurl = vm.url;
            }
            const wilayah = {
                propId: form.propId,
                kabId: form.kabId,
                kecId: form.kecId,
                desaId: form.desaId
            }
            let formData = new FormData();
            formData.append('orgId', form.orgId);
            formData.append('kepala', form.kepala);
            formData.append('wakil', form.wakil);
            formData.append('wilayah', JSON.stringify(wilayah));
            if(form.file1!="-" || form.file2!="-" || form.file3!="-") {
                formData.append('files', form.file1[0]);            
                formData.append('files', form.file2[0]);            
                formData.append('files', form.file3[0]);            
            }
            const config = {
                method: vmethod,
                headers: {
                    'Content-Type': 'multipart/form-data'                    
                },
                url: dataurl,
                data : formData
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
            if (localStorage.getItem("organisasi")==null) {
                organisasi();
            }
            vm.listOrganisasi = JSON.parse(localStorage.getItem("organisasi"));
        },
        getData() {
            let vm = this;
            vm.payload = {
                query : `query {
                    getCustomerOrgs
                    {
                        id
                        customerId
                        orgId
                        kepala
                        wakil
                        file
                        surat
                        wilayah
                        status
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.urlgraphql,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                let sendwilayah = {
                    desaId: "",
                    kecamatanId: "",
                    kabupatenId: "",
                    propinsiId: ""
                };
                if (response.data.data.getCustomerOrgs.length==0) {
                    bus.emit("openPropinsiField",sendwilayah);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: "Data Not Found",
                    });
                }
                else {
                    const data = response.data.data.getCustomerOrgs;
                    vm.idori = data[0].id;
                    vm.form.orgId = data[0].orgId;
                    vm.form.kepala = data[0].kepala;
                    vm.form.wakil = data[0].wakil;
                    if(data[0].wilayah!="") {
                        const wilayah = JSON.parse(data[0].wilayah);
                        vm.form.propId = wilayah.propId;
                        vm.form.kabId = wilayah.kabId;
                        vm.form.kecId = wilayah.kecId;
                        vm.form.desaId = wilayah.desaId;
                        sendwilayah = {
                            desaId: wilayah.desaId,
                            kecamatanId: wilayah.kecId,
                            kabupatenId: wilayah.kabId,
                            propinsiId: wilayah.propId
                        }
                    }
                    const datafile = JSON.parse(data[0].file);
                    vm.form.file1="-";
                    vm.form.file2="-";
                    vm.form.file3="-";
                    vm.previewMedia1.push({
                        name: "kopsurat",
                        src: vm.fileUrl + "/" + datafile[0].filename,
                        type: "image"
                    });
                    vm.previewMedia2.push({
                        name: "stempel",
                        src: vm.fileUrl + "/" + datafile[1].filename,
                        type: "image"
                    });
                    vm.previewMedia3.push({
                        name: "tandatangan",
                        src: vm.fileUrl + "/" + datafile[2].filename,
                        type: "image"
                    });
                    bus.emit("openPropinsiField",sendwilayah);
                    bus.emit("openKabupatenField",sendwilayah);
                    bus.emit("openKecamatanField",sendwilayah);
                    bus.emit("openDesaField",sendwilayah);
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.file1)) {
                err_message += "- Kop Surat Kosong<br>";
            }
            if (_.isEmpty(vm.form.file2)) {
                err_message += "- Stempel Kosong<br>";
            }
            if (_.isEmpty(vm.form.file3)) {
                err_message += "- Tanda Tangan Kosong<br>";
            }
            if (_.isEmpty(vm.form.orgId)) {
                err_message += "- Organisasi Kosong<br>";
            }
            if (_.isEmpty(vm.form.kepala)) {
                err_message += "- Kepala Organisasi Kosong<br>";
            }
            if (_.isEmpty(vm.form.wakil)) {
                err_message += "- Wakil Organisasi Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
        processFile1(index,event) {
            let vm = this;
            vm.previewMedia1 = [];
            vm.fileupload1 = [];
            vm.form.file1 = event.target.files;
            const upload_files = event.target.files;
            const MAX_FILESIZE = process.env.MIX_MAX_UPLOAD_SIZE || 64;
            // vm.convertImageToBase64(event);
            if (upload_files.length > 0) {
                let error_file = "";
                for (let i = 0; i < upload_files.length; i ++) {
                    let err = "";
                    const file_name = upload_files[i].name;
                    const file_extension = file_name.split('.').pop().toLowerCase();
                    const media_type = vm.validateFile(file_extension);
                    if (media_type == "") err = "filetype";

                    const checkFilesize = upload_files[i].size / (1024 ** 2); // bytes to megabytes
                    if (checkFilesize > MAX_FILESIZE) err = "filesize";

                    if (_.isEmpty(err)) {
                        upload_files[i].media_type = media_type;
                        vm.isFileUpload1=true;
                        vm.previewMedia1.push({
                            id: 0,
                            name: file_name,
                            src: URL.createObjectURL(upload_files[i]),
                            type: media_type,
                            data: upload_files[i]
                        });
                        vm.fileupload1.push(upload_files[i]);
                    } else {
                        if (err == "filetype") error_file += `${file_name} not valid for upload. Please select the valid file<br>`;
                        if (err == "filesize") error_file += `${file_name} filesize more than 64 MB.<br>`;
                    }
                }
                if (error_file.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: error_file,
                    });
                }
            }
        },
        processFile2(index,event) {
            let vm = this;
            vm.previewMedia2 = [];
            vm.fileupload2 = [];
            vm.form.file2 = event.target.files;
            const upload_files = event.target.files;
            const MAX_FILESIZE = process.env.MIX_MAX_UPLOAD_SIZE || 64;
            // vm.convertImageToBase64(event);
            if (upload_files.length > 0) {
                let error_file = "";
                for (let i = 0; i < upload_files.length; i ++) {
                    let err = "";
                    const file_name = upload_files[i].name;
                    const file_extension = file_name.split('.').pop().toLowerCase();
                    const media_type = vm.validateFile(file_extension);
                    if (media_type == "") err = "filetype";

                    const checkFilesize = upload_files[i].size / (1024 ** 2); // bytes to megabytes
                    if (checkFilesize > MAX_FILESIZE) err = "filesize";

                    if (_.isEmpty(err)) {
                        upload_files[i].media_type = media_type;
                        vm.isFileUpload2=true;
                        vm.previewMedia2.push({
                            id: 0,
                            name: file_name,
                            src: URL.createObjectURL(upload_files[i]),
                            type: media_type,
                            data: upload_files[i]
                        });
                        vm.fileupload2.push(upload_files[i]);
                    } else {
                        if (err == "filetype") error_file += `${file_name} not valid for upload. Please select the valid file<br>`;
                        if (err == "filesize") error_file += `${file_name} filesize more than 64 MB.<br>`;
                    }
                }
                if (error_file.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: error_file,
                    });
                }
            }
        },
        processFile3(index,event) {
            let vm = this;
            vm.previewMedia3 = [];
            vm.fileupload3 = [];
            vm.form.file3 = event.target.files;
            const upload_files = event.target.files;
            const MAX_FILESIZE = process.env.MIX_MAX_UPLOAD_SIZE || 64;
            // vm.convertImageToBase64(event);
            if (upload_files.length > 0) {
                let error_file = "";
                for (let i = 0; i < upload_files.length; i ++) {
                    let err = "";
                    const file_name = upload_files[i].name;
                    const file_extension = file_name.split('.').pop().toLowerCase();
                    const media_type = vm.validateFile(file_extension);
                    if (media_type == "") err = "filetype";

                    const checkFilesize = upload_files[i].size / (1024 ** 2); // bytes to megabytes
                    if (checkFilesize > MAX_FILESIZE) err = "filesize";

                    if (_.isEmpty(err)) {
                        upload_files[i].media_type = media_type;
                        vm.isFileUpload3=true;
                        vm.previewMedia3.push({
                            id: 0,
                            name: file_name,
                            src: URL.createObjectURL(upload_files[i]),
                            type: media_type,
                            data: upload_files[i]
                        });
                        vm.fileupload3.push(upload_files[i]);
                    } else {
                        if (err == "filetype") error_file += `${file_name} not valid for upload. Please select the valid file<br>`;
                        if (err == "filesize") error_file += `${file_name} filesize more than 64 MB.<br>`;
                    }
                }
                if (error_file.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: error_file,
                    });
                }
            }
        },
        validateFile(file_extension) {
            let media_type = "";
            // audio
            if (["mp3","wav","aiff","au","m4a","wma"].indexOf(file_extension) >= 0) {
                media_type = "audio";
            }

            // document
            if (["docx","doc","pdf","xls","xlsx","html","htm","ods","ppt","pptx","txt"].indexOf(file_extension) >= 0) {
                media_type = "document";
            }

            // image
            if (["jpeg","jpg","png","peg","gif","tiff"].indexOf(file_extension) >= 0) {
                media_type = "image";
            }
            // video
            if (["webm","mkv","flv","ogg","avi","mov","wmv","mp4","3gp"].indexOf(file_extension) >= 0) {
                media_type = "video";
            }

            return media_type;
        },
        removeMedia1(indexmedia,index) {
            let vm = this;
            if (!!vm.previewMedia1[indexmedia]) {
                const media = vm.previewMedia1[indexmedia];
                vm.deletedMedia1.push({
                    id: media.id,
                    media_filepath: media.src_original,
                    media_thumbnail_filepath: media.src_thumbnail,
                });
                vm.form.file1=null;
            } 
            vm.previewMedia1.splice(indexmedia, 1);
            vm.form.foto1 = "";
            if(vm.previewMedia1.length==0)
            {
                vm.isFileUpload1=false;
            }
        },
        removeMedia2(indexmedia,index) {
            let vm = this;
            if (!!vm.previewMedia2[indexmedia]) {
                const media = vm.previewMedia2[indexmedia];
                vm.deletedMedia1.push({
                    id: media.id,
                    media_filepath: media.src_original,
                    media_thumbnail_filepath: media.src_thumbnail,
                }); 
                vm.form.file2=null;
            } 
            vm.previewMedia2.splice(indexmedia, 1);
            vm.form.foto1 = "";
            if(vm.previewMedia2.length==0)
            {
                vm.isFileUpload2=false;
            }
        },
        removeMedia3(indexmedia,index) {
            let vm = this;
            if (!!vm.previewMedia3[indexmedia]) {
                const media = vm.previewMedia3[indexmedia];
                vm.deletedMedia1.push({
                    id: media.id,
                    media_filepath: media.src_original,
                    media_thumbnail_filepath: media.src_thumbnail,
                }); 
                vm.form.file3=null;
            } 
            vm.previewMedia3.splice(indexmedia, 1);
            vm.form.foto1 = "";
            if(vm.previewMedia3.length==0)
            {
                vm.isFileUpload3=false;
            }
        },
    },
    computed: {
    },
    watch: {
    },
    mounted() {
      let vm = this;
    },
    created() {
      const vm = this;
      vm.initData();
      vm.getData();
      bus.on("openOrg", (data) => {
        if(data.propinsiId!="") vm.form.propId = data.propinsiId;
        if(data.kabupatenId!="") vm.form.kabId = data.kabupatenId;
        if(data.kecamatanId!="") vm.form.kecId = data.kecamatanId;
        if(data.desaId!="") vm.form.desaId = data.desaId;
      });
    },    
};
</script>