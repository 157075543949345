<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            File
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="custom-file">
                                            <input type="file" id="file" class="custom-file-input" @change="processFile(index,$event)">
                                            <label class="custom-file-label">Choose file</label>												
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Foto
                                        </label>
                                        <div class="row ml-1">
                                            <div class="card mr-3 mb-3 w-12rem" v-for="(media, indexmedia) in previewMedia" :key="indexmedia">
                                                <span 
                                                    class="text-danger close btn btn-remove-media" 
                                                    @click="removeMedia(indexmedia, index)"
                                                ><i class="fa fa-times"></i></span>
                                                <img v-if="media.type == 'image'" class="card-img-top h-100px" :src="media.src" alt="image">
                                                <img v-else class="mx-auto avatar-50px" :src="'/images/'+media.type+'.png'" alt="media">
                                                <div class="card-footer text-muted">
                                                    <a :href="media.src" target="_blank">{{ media.name }}</a>
                                                </div>
                                            </div>
                                        </div>                

                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Nama
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Susanto" v-model="form.nama">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            NoHP
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="081227881,08127737" v-model="form.contact" :disabled="nohpDisabled">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            Alamat
                                        </label>
                                        <input type="text" class="form-control" placeholder="Jl Melati no 10" v-model="form.alamat">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            No Telp
                                        </label>
                                        <input type="text" class="form-control" placeholder="0218177123" v-model="form.notelp">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <AgamaField :agamaIdProp="form.agamaId"></AgamaField> 
                                    <div class="form-group col-md-6">
                                        <label>Email</label>
                                        <input type="text" class="form-control" placeholder="anton@gmail.com" v-model="form.email">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>IG</label>
                                        <input type="text" class="form-control" placeholder="@contoh" v-model="form.ig">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>FB</label>
                                        <input type="text" class="form-control" placeholder="Sasa Sandi" v-model="form.fb">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Youtube</label>
                                        <input type="text" class="form-control" placeholder="SasaSandi" v-model="form.youtube">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Website</label>
                                        <input type="text" class="form-control" placeholder="https://google.com" v-model="form.website">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label>Biodata </label>
                                        <textarea class="form-control" rows="20" cols="100" v-model="form.biodata"></textarea>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="btn-group float-right">
                                        <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                            <span class="btn-icon-left text-info">
                                                <i class="fa fa-save color-info"></i>
                                            </span>Save
                                        </button>
                                        <button type="button" class="btn btn-light" @click="back()">
                                            <span class="btn-icon-left text-info">
                                                <i class="fa fa-cancel color-info"></i>
                                            </span>Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { bus } from "../main";
import AgamaField from './field/AgamaField';
import { parseJwt } from '../helpers/global';
export default {
    components: { AgamaField },
    name: 'Customer',
    data() {
        return {
            fileUrl: process.env.VUE_APP_URL_BACKEND + "/api/image",
            idori:"",
            previewMedia: [],
            deletedMedia: [],
            fileupload:[],
            isFileUpload:false,
            nohpDisabled: false,
            form: {
                nama: "",
                email: null,
                contact: "",
                alamat: "",
                notelp: "",
                agamaId: null,
                ig: "",
                tiktok: "",
                youtube: "",
                fb: "",
                website: "",
                biodata: "Prestasi:\n\n\nRiwayat Hidup:\n\n\nRiwayat Pekerjaan:\n\n\nVisi/Misi:\n\n\nBiodata Lengkap:",
                file: null
            },
            url: "/api/customer/",
            profile: ""
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        getProfile() {
            const vm = this;
            if(localStorage.getItem("profile")) {
                const profile  = JSON.parse(localStorage.getItem("profile"));
                vm.profesi  = profile.profesi;
            }
            else {
                vm.profesi  = null;
            }
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            let vmethod;
            if(vm.idori!="") {
                vmethod = "PUT";
                vm.url = vm.url + vm.idori;
            }
            else {
                vmethod = "POST";
            }
            let formData = new FormData();
            formData.append('nama', form.nama);
            formData.append('alamat', form.alamat);
            formData.append('contact', form.contact);
            formData.append('ig', form.ig);
            formData.append('tiktok', form.tiktok);
            formData.append('youtube', form.youtube);
            formData.append('fb', form.fb);
            formData.append('website', form.website);
            formData.append('biodata', form.biodata);
            formData.append('agamaId', form.agamaId);
            formData.append('email', form.email);
            if(form.file!=null) {
                formData.append('files', form.file[0]);            
            }
            const config = {
                method: vmethod,
                headers: {
                    'Content-Type': 'multipart/form-data'                    
                },
                url: vm.url,
                data : formData
            }
            axiosKpapi(config)
            .then((response) => {
                Swal.fire({
                icon: 'success',
                title: 'Yeay...',
                text: "Success",
                confirmButtonText: 'Ok'
                }).then((result) => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
            const token  = localStorage.getItem("token") != null ? JSON.parse(localStorage.getItem("token")) : null;
            const userId = parseJwt(token)['user'];
            vm.form.contact = userId;
            vm.getProfile();
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (_.isEmpty(vm.form.contact)) {
                err_message += "- Contact Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
        processFile(index,event) {
            let vm = this;
            vm.previewMedia = [];
            vm.fileupload = [];
            vm.form.file = event.target.files;
            const upload_files = event.target.files;
            const MAX_FILESIZE = process.env.MIX_MAX_UPLOAD_SIZE || 64;
            // vm.convertImageToBase64(event);
            if (upload_files.length > 0) {
                let error_file = "";
                for (let i = 0; i < upload_files.length; i ++) {
                    let err = "";
                    const file_name = upload_files[i].name;
                    const file_extension = file_name.split('.').pop().toLowerCase();
                    const media_type = vm.validateFile(file_extension);
                    if (media_type == "") err = "filetype";

                    const checkFilesize = upload_files[i].size / (1024 ** 2); // bytes to megabytes
                    if (checkFilesize > MAX_FILESIZE) err = "filesize";

                    if (_.isEmpty(err)) {
                        upload_files[i].media_type = media_type;
                        vm.isFileUpload=true;
                        vm.previewMedia.push({
                            id: 0,
                            name: file_name,
                            src: URL.createObjectURL(upload_files[i]),
                            type: media_type,
                            data: upload_files[i]
                        });
                        vm.fileupload.push(upload_files[i]);
                    } else {
                        if (err == "filetype") error_file += `${file_name} not valid for upload. Please select the valid file<br>`;
                        if (err == "filesize") error_file += `${file_name} filesize more than 64 MB.<br>`;
                    }
                }
                if (error_file.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: error_file,
                    });
                }
            }
        },
        validateFile(file_extension) {
            let media_type = "";
            // audio
            if (["mp3","wav","aiff","au","m4a","wma"].indexOf(file_extension) >= 0) {
                media_type = "audio";
            }

            // document
            if (["docx","doc","pdf","xls","xlsx","html","htm","ods","ppt","pptx","txt"].indexOf(file_extension) >= 0) {
                media_type = "document";
            }

            // image
            if (["jpeg","jpg","png","peg","gif","tiff"].indexOf(file_extension) >= 0) {
                media_type = "image";
            }
            // video
            if (["webm","mkv","flv","ogg","avi","mov","wmv","mp4","3gp"].indexOf(file_extension) >= 0) {
                media_type = "video";
            }

            return media_type;
        },
        removeMedia(indexmedia,index) {
            let vm = this;
            if (!!vm.previewMedia[indexmedia]) {
                const media = vm.previewMedia[indexmedia];
                vm.deletedMedia.push({
                    id: media.id,
                    media_filepath: media.src_original,
                    media_thumbnail_filepath: media.src_thumbnail,
                }); 
            } 
            vm.previewMedia.splice(indexmedia, 1);
            vm.form.foto = "";
            if(vm.previewMedia.length==0)
            {
                vm.isFileUpload=false;
            }
        },
        convertImageToBase64(event) {
            let vm = this;
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    vm.form.foto = reader.result;
                }
                reader.readAsDataURL(file);
            }
        }        
    },
    computed: {
        activeCustomer: {
            get() {
                return this.$store.getters['customers/getActiveCustomerManage'];
            },
            set(value) {
                this.$store.commit('customers/setActiveCustomerManage', value);
            }
        },
        isFormCustomerActive: {
            get() {
                return this.$store.getters['customers/getShowFormCustomer'];
            },
            set(value) {
                this.$store.commit('customers/setShowFormCustomer', value);
            }
        },
        listCustomer: {
            get() {
                return this.$store.getters['customers/getListCustomer'];
            },
            set(value) {
                this.$store.commit('customers/setListCustomer', value);
            }
        },
    },
    watch: {
        activeCustomer: {
            immediate: true,
            handler(value) {
                let vm = this;
                if(value===null) return;
                //if (Object.keys(value).length === 0 && value.constructor === Object) return;
                if (value.length === 0) return;
                vm.idori = value.id;
                vm.form.nama = value.nama;
                let contact = JSON.parse(value.contact);
                contact = contact.replace("[","");
                contact = contact.replace("]","");
                vm.form.contact = contact;
                const datafoto = JSON.parse(value.foto);
                if(value.foto!="" && datafoto[0]!=null)
                {
                    vm.previewMedia.push({
                      name: "foto",
                      src: vm.fileUrl + "/" + datafoto[0].filename,
                      type: "image"
                  });
                }
            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
      bus.on("openCustomer", (data) => {
        if(data!=null) {
            vm.form.agamaId = data.agamaId;
            vm.form.nama = data.nama;
            vm.form.email = data.User.email;
            vm.form.contact = data.contact;
            vm.form.alamat = data.alamat;
            vm.form.notelp = data.notelp;
            vm.form.ig = data.ig;
            vm.form.tiktok = data.tiktok;
            vm.form.youtube = data.youtube;
            vm.form.fb = data.fb;
            vm.form.website = data.website;
            if(data.biodata!=undefined) {
                vm.form.biodata = data.biodata
            }
            bus.emit("openAgamaField",data.agamaId);
        }
      });
      bus.on("openAgamaCustomer", (agamaId) => {
        vm.form.agamaId = agamaId;
      })
    },    
};
</script>