<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            Nama
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Sancoko" v-model="form.nama">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>
                                            No HP
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="628127166155" v-model="form.nohp" @input="autoFillCountryCode">
                                    </div>
                                    <TpsField :tpsId="form.tpsId"></TpsField> 
                                    <div class="form-group col-md-6">
                                        <label>
                                            Kode TPS (auto generated)
                                        </label>
                                        <input type="text" class="form-control" v-model="form.kodetps" :disabled="kodetpsDisabled">
                                    </div>
                                    <PropinsiField :propId="form.propId" :disable="propDisabled"></PropinsiField> 
                                    <KabupatenField :propId="form.propId" :kabId="form.kabId" :disable="kabDisabled"></KabupatenField> 
                                    <KecamatanField :kabId="form.kabId" :kecId="form.kecId"></KecamatanField> 
                                    <DesaField :kecId="form.kecId" :desaId="form.desaId"></DesaField> 
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { bus } from "../main";

import PropinsiField from './field/PropinsiField';
import KabupatenField from './field/KabupatenField';
import KecamatanField from './field/KecamatanField';
import DesaField from './field/DesaField';
import TpsField from './field/TpsField';

export default {
    components: { PropinsiField, KabupatenField, KecamatanField, DesaField, TpsField },
    name: 'SaksiSuara',
    data() {
        return {
            idori:"",
            kodetpsDisabled: true,            
            propDisabled: true,            
            kabDisabled: true,            
            form: {
                nama: "",
                nohp: "",
                kodetps: "",
                parpolId: null,
                propId: null,
                kabId: null,
                kecId: null,
                desaId: null,
                tpsId: null,
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        autoFillCountryCode() {
            const vm = this;
            if (!vm.form.nohp.startsWith('62')) {
                if(vm.form.nohp==0) {
                    vm.form.nohp = '62';
                }
                else {
                    vm.form.nohp = '62'+vm.form.nohp;
                }
            }
            else if(vm.form.nohp=="620") {
                vm.form.nohp = '62'+vm.form.nohp;
            }
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            const tmptpsId = form.tpsId.split("_");
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateSaksiSuara(id : "${vm.idori}"
                            input: {
                                nama: "${form.nama}"
                                nohp: "${form.nohp}"
                                tpsId: "${tmptpsId[0]}"
                                propinsiId: "${form.propId}"
                                kabupatenId: "${form.kabId}"
                                kecamatanId: "${form.kecId}"
                                desaId: "${form.desaId}"
                            }
                        )
                        {
                            nama
                            nohp
                            tpsId
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setSaksiSuara (input : {
                            nama: "${form.nama}"
                            tpsId: "${tmptpsId[0]}"
                            nohp: "${form.nohp}"
                            propinsiId: "${form.propId}"
                            kabupatenId: "${form.kabId}"
                            kecamatanId: "${form.kecId}"
                            desaId: "${form.desaId}"
                        })
                        {
                            nama
                            nohp
                            tpsId
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if(response.data.errors) {
                    let msg = "";
                    const msgerror = response.data.errors;
                    const regex = /Validation error/gi; // Ekspresi reguler untuk mencocokkan "validation error" (tidak peduli huruf besar/kecil)
                    const matches = msgerror[0].message.match(regex);
                    if (matches) {
                        msg = `No HP ${vm.form.nohp} sudah digunakan`;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                    else {
                        msg = msgerror[0].message;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                }
                else {

                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (_.isEmpty(vm.form.nohp)) {
                err_message += "- No HP Kosong<br>";
            }
            if (_.isEmpty(vm.form.tpsId)) {
                err_message += "- TPS Kosong<br>";
            }
            if (_.isEmpty(vm.form.propId)) {
                err_message += "- Propinsi Kosong<br>";
            }
            if (_.isEmpty(vm.form.kabId)) {
                err_message += "- Kabupaten Kosong<br>";
            }
            if (_.isEmpty(vm.form.kecId)) {
                err_message += "- Kecamatan Kosong<br>";
            }
            if (_.isEmpty(vm.form.desaId)) {
                err_message += "- Desa Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
    },
    computed: {
        activeSaksiSuara: {
            get() {
                return this.$store.getters['saksisuaras/getActiveSaksiSuaraManage'];
            },
            set(value) {
                this.$store.commit('saksisuaras/setActiveSaksiSuaraManage', value);
            }
        },
        isFormSaksiSuaraActive: {
            get() {
                return this.$store.getters['saksisuaras/getShowFormSaksiSuara'];
            },
            set(value) {
                this.$store.commit('saksisuaras/setShowFormSaksiSuara', value);
            }
        },
        listSaksiSuara: {
            get() {
                return this.$store.getters['saksisuaras/getListSaksiSuara'];
            },
            set(value) {
                this.$store.commit('saksisuaras/setListSaksiSuara', value);
            }
        },
    },
    watch: {
        activeSaksiSuara: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;

                vm.idori                = value.id;
                vm.form.nama            = value.nama;              
                vm.form.nohp            = value.nohp;              
                vm.form.tpsId           = value.tpsId + "_" + value.propinsiId + "_" + value.kabupatenId;              
                vm.form.kodetps         = value.kodetps;              
                vm.form.propId          = value.propinsiId;              
                vm.form.kabId           = value.kabupatenId;              
                vm.form.kecId           = value.kecamatanId;              
                vm.form.desaId          = value.desaId;
                const datasend = {
                    tpsId : vm.form.tpsId
                }
                bus.emit("openTpsField",datasend);

            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
      bus.on("openSaksiSuara", (data) => {
        if(!!data.propinsiId && data.propinsiId!="") vm.form.propId = data.propinsiId;
        if(!!data.kabupatenId && data.kabupatenId!="") vm.form.kabId = data.kabupatenId;
        if(!!data.kecamatanId && data.kecamatanId!="") vm.form.kecId = data.kecamatanId;
        if(!!data.desaId && data.desaId!="") vm.form.desaId = data.desaId;
        if(!!data.tpsId && data.tpsId!="") {
            const tmptpsId = data.tpsId.split("_");
            vm.form.tpsId = tmptpsId[0];
            vm.form.propId = tmptpsId[1];
            vm.form.kabId = tmptpsId[2];
            const datasend = {
                desaId : "",
                kecamatanId : "",
                kabupatenId : tmptpsId[2],
                propinsiId : tmptpsId[1]
            }
            bus.emit("openPropinsiField",datasend);
            bus.emit("openKabupatenField",datasend);

        } 
      });

    },    
};
</script>