<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-group col-md-6">
                                    <label>
                                        Keyword
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" class="form-control" placeholder="Keyword" v-model="form.nama">
                                </div>               

                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { parseJwt, findMatch } from '../helpers/global';
export default {
    name: 'Keyword',
    data() {
        return {
            idori:"",
            form: {
                tipe: "",
                nama: ""
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateKonfigurasi(id : "${vm.idori}"
                            input: {
                                nama: "${form.nama}"
                            }
                        )
                        {
                            nama
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setKonfigurasi (input : {
                            tipe: "${form.tipe}"
                            nama: "${form.nama}"
                        })
                        {
                            nama
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
        },
        getData() {
            let vm = this;
            const token  = localStorage.getItem("token") != null ? JSON.parse(localStorage.getItem("token")) : null;
            const userId = parseJwt(token)['user'];
            vm.payload = {
                query : `query {
                    getKonfigurasi(
                        userId: "${userId}"
                        tipe: "Keyword"
                    )
                    {
                        id
                        nama
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (!!response.data.errors) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
                else {
                    const data = response.data.data.getKonfigurasi;
                    if(data!=null)
                    {
                        vm.form.tipe = data.tipe;
                        vm.form.nama = data.nama;
                        vm.idori = data.id;
                    }
                    else {
                        vm.form.tipe = "Keyword";
                    }
                }
            })
            .catch((error) => {
                const reserror = findMatch(error.message,"401");
                if(reserror) {
                    localStorage.removeItem("token");            
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: "Token Expired",
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.href = `/`;
                    });
                }
                else {
                    Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: error.message,
                    });
                }
            });
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Keyword Kosong<br>";
            }

            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },         
    },
    computed: {
    },
    watch: {
    },
    mounted() {
      let vm = this;
    },
    created() {
      const vm = this;
      vm.getData();
    },    
};
</script>