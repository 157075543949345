<template>
    <div class="modal fade none-border" id="data-detail">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><strong>Preview</strong></h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <iframe :src="urliframe" width="100%" height="500px" frameborder="0"></iframe>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { bus } from "../../main";
export default {
    name: 'TemplateSuratPreviewModal',
    data() {
        return {
            fileUrl: process.env.VUE_APP_URL_BACKEND + "/api/image",
            urliframe: "",
        }
    },
    methods: {
    },
    created() {
        let vm = this; 
        bus.on("openTemplateSuratPreviewModal", (data) => {
            const fileid = JSON.parse(data.file);
            vm.urliframe = vm.fileUrl + "/" + fileid[0].filename;
        });
    },
}
</script>