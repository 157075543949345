<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="col-md-12 text-right">
                            <button type="button" class="btn btn-success" @click="add()">
                                <span class="btn-icon-left text-info">
                                    <i class="fa fa-plus color-info"></i>
                                </span>Add
                            </button>
                        </div>                        
                    </div>                    
                    <div class="table-responsive">
                        <table ref="templatedatatable" class="display min-w850">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="checkAll" required="">
                                            <label class="custom-control-label" for="checkAll"></label>
                                        </div>
                                    </th>
                                    <th>Nama</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(template, index) in listTemplate" :key="template.id">
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheckBox2" required="">
                                            <label class="custom-control-label" for="customCheckBox2"></label>
                                        </div>
                                    </td>
                                    <td>{{ template.jenissuratId }}</td>
                                    <td>
                                        <span class="badge light badge-danger">
                                            <i v-if="template.status.toLowerCase()=='draft'" class="fa fa-circle text-warning mr-1"></i>
                                            <i v-else-if="template.status.toLowerCase()=='active'" class="fa fa-circle text-success mr-1"></i>
                                            <i v-else-if="template.status.toLowerCase()=='reject'" class="fa fa-circle text-danger mr-1"></i>
                                            {{ template.status }}
                                        </span>
                                    </td>
                                    <td>{{ JSON.parse(formattedDate(template.createdAt)) }}</td>
                                    <td>
                                        <div class="form-row">
                                            <div class="d-flex">
                                                <a href="#" class="btn btn-primary shadow btn-xs sharp mr-1" @click="edit(template)"><i class="fa fa-pencil"></i></a>
                                                <a href="#" class="btn btn-danger shadow btn-xs sharp" @click="status(template,`delete`)"><i class="fa fa-trash"></i></a>
                                            </div>
                                            <div class="dropdown ml-auto text-right">
                                                <div class="btn-link" data-toggle="dropdown">
                                                    <svg width="50px" height="50px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="50" height="50"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                                </div>
                                                <ul class="dropdown-menu dropdown-menu-right">
                                                    <li class="dropdown-item" ><a href="#" @click="status(template,`active`)">Active</a></li>
                                                    <li class="dropdown-item"><a href="#" @click="status(template,`reject`)">Reject</a></li>
                                                    <li class="dropdown-item"><a href="#" @click="status(template,`draft`)">Draft</a></li>
                                                    <li class="dropdown-item"><a href="#" data-toggle="modal" data-target="#data-detail" @click="showmodal(template)">Previews</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- Modal Detail -->
            <TemplateSuratPreviewModal></TemplateSuratPreviewModal>     
        </div>
    </div>
</template>
<script>
import { bus } from "../main";
import { formatDateUnixToHuman } from '../helpers/global';
import TemplateSuratPreviewModal from './modal/TemplateSuratPreviewModal';
import { category } from '../helpers/loaddata';

export default {
    components: { TemplateSuratPreviewModal },
    name: 'TemplateSuratList',
    data() {
        return {
            table: "",
            url:"/graphql",
            payload: ""  
        }
    },
    watch: {
        listTemplate(nv, ov) {
            if (nv.length == 0) return;
        }
    },
    methods: {
        add() {
            this.isFormSuratTemplateActive = true;
            this.activeSuratTemplate = {};
        },
        edit(data) {
            let vm = this;
            vm.isFormSuratTemplateActive = true;
            vm.activeSuratTemplate = data;
        },
        status(data,status) {
            let vm = this;
            if(status=="delete") {
                vm.payload = {
                    query : 
                    `
                        mutation {    
                            deleteCustomerSurat(id : "${data.id}")
                            {
                                id
                                jenissuratId
                                status
                            }
                        }                
                    `
                }
                Swal.fire({
                    title: "Are you sure ?",
                    text: "You want to delete",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const config = {
                            method: "POST",
                            url: vm.url,
                            data : vm.payload
                        }
                        axiosKpapi(config)
                        .then((response) => {
                            if (response.data.data) {
                                Swal.fire({
                                icon: 'success',
                                title: 'Yeay...',
                                text: "Success",
                                confirmButtonText: 'Ok'
                                }).then((result) => {
                                    window.location.reload();
                                })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops!',
                                    text: JSON.stringify(response.data.errors),
                                });
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: error.message,
                            });
                        });
                    }
                });
            }
            else {
                vm.payload = {
                    query : 
                    `
                        mutation {    
                            updateCustomerSurat(id : "${data.id}"
                                input: {
                                    status: "${status.toUpperCase()}"
                                }
                            )
                            {
                                id
                                jenissuratId
                                status
                            }
                        }                
                    `
                }
                const config = {
                    method: "POST",
                    url: vm.url,
                    data : vm.payload
                }
                axiosKpapi(config)
                .then((response) => {
                    if (response.data.data) {
                        Swal.fire({
                        icon: 'success',
                        title: 'Yeay...',
                        text: "Success",
                        confirmButtonText: 'Ok'
                        }).then((result) => {
                            window.location.reload();
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: JSON.stringify(response.data.errors),
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: error.message,
                    });
                });
            }
        },
        initData() {
            let vm = this;
            vm.payload = {
                query : `query {
                    getCustomerSurats
                    {
                        id
                        customerId        
                        jenissuratId
                        file
                        alamat
                        payload
                        desaId
                        kecamatanId
                        kabupatenId
                        propinsiId
                        status
                        createdAt
                        updatedAt
                        createdBy
                        updatedBy
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listTemplate = response.data.data.getCustomerSurats;
                    this.$nextTick(function() {
                        $(this.$refs.templatedatatable).DataTable({
                            "paging": true,        // Aktifkan paging
                            "pageLength": 10,      // Jumlah baris per halaman
                            "lengthChange": true, // Tampilkan atau sembunyikan dropdown ganti jumlah baris            
                        });
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        formattedDate(date) {
            return formatDateUnixToHuman(date,"dd/MM/yyyy HH:mm:ss");
        },
        showmodal(data) {
            bus.emit("openTemplateSuratPreviewModal",data);
        }
    },
    computed: {
        activeSuratTemplate: {
            get() {
                return this.$store.getters['surattemplates/getActiveSuratTemplateManage'];
            },
            set(value) {
                this.$store.commit('surattemplates/setActiveSuratTemplateManage', value);
            }
        },
        isFormSuratTemplateActive: {
            get() {
                return this.$store.getters['surattemplates/getShowFormSuratTemplate'];
            },
            set(value) {
                this.$store.commit('surattemplates/setShowFormSuratTemplate', value);
            }
        },
        listTemplate: {
            get() {
                return this.$store.getters['surattemplates/getListSuratTemplate'];
            },
            set(value) {
                this.$store.commit('surattemplates/setListSuratTemplate', value);
            }
        },
    },
    mounted() {
    },
    created() {
      const vm = this;
      if (vm.listTemplate==undefined) vm.initData();
      category();      
    }
};
</script>