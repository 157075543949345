const profiles = {
    namespaced: true,
    state: {
      // ACTIVE
      activeProfile: [],
  
      // LIST
      listProfiles: [],
  
      // SHOW
      showFormProfiles: false,
    },
    getters: {
      getShowFormProfile: (state) => {
        return state.showFormProfile;
      },
      getActiveProfile: (state) => {
        return state.activeProfile;
      },
      getActiveProfileManage: (state) => {
        return state.activeProfile;
      },
      getListProfile: (state) => {
        return state.listProfile;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormProfile(state, value) {
        state.showFormProfile = value;
      },
      setActiveProfileManage(state, value) {
        state.activeProfile = value;
      },  
      setListProfile(state, value) {
        state.listProfile = value;
      },  
     },
    actions: { }
  }
  
  export default profiles;