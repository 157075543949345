<template>
    <div class="form-group col-md-6">
        <label>Propinsi</label>
        <select id="inputState" class="form-control" 
            v-model="propinsiId"
            @change="onChange($event)"
            :disabled="disable"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listPropinsi"
                :key="list.id"
                :value="list.id"
                >{{ list.nama }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'PropinsiField',
    props : ['propId','disable'],
    data() {
        return {
            listPropinsi:"",
            propinsiId:"",
            disableInput: true,
        }
    },
    methods: {
        onChange(event) {
            const propinsiId = event.target.value;
            const datasend = {
                desaId : "",
                kecamatanId : "",
                kabupatenId : "",
                propinsiId : propinsiId
            }
            bus.emit("openUser",datasend);
            bus.emit("openSaksiSuara",datasend);
            bus.emit("openOrg",datasend);
            bus.emit("openTemplateSurat",datasend);
            bus.emit("openKabupatenField",datasend);
            bus.emit("openKecamatanField",-1);
            bus.emit("openDesaField",-1);
        },
        initData() {
            let vm = this;
            vm.getData();            
        },
        getData() {
            let vm = this;
            const payload = {
                query : `query {
                    getPropinsis
                    {
                        id
                        nama
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listPropinsi = response.data.data.getPropinsis;
                }
            })
            .catch((error) => {
                console.log("error-propinsiField",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        if(!!vm.propId) {
            vm.propinsiId = vm.propId;
        }
    },
    created() {
        let vm = this;
        vm.initData();
        bus.on("openPropinsiField", (data) => {
            vm.propinsiId = data.propinsiId;
        });
    },
}
</script>