
import { createApp } from 'vue'
import router from './router/index'
import App from './App.vue'
import swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import store from "./store";
import mitt from 'mitt';

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageFilter from 'filepond-plugin-image-filter';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginGetFile from 'filepond-plugin-get-file';


// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';



// Create FilePond component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType, 
    FilePondPluginImagePreview,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateSize,
    FilePondPluginImageTransform,
    FilePondPluginImageExifOrientation,
    FilePondPluginImageCrop,
    FilePondPluginImageFilter,
    FilePondPluginImageResize,
    FilePondPluginGetFile
);

window._ = require('lodash');
window.axios = require('axios');
window.Swal = swal;

window.axios.defaults.baseURL = process.env.VUE_APP_URL_BACKEND;
let kopapi_bearer_token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
kopapi_bearer_token = 'Bearer ' + kopapi_bearer_token;
const axiosKpapi = axios.create({
  baseURL: process.env.VUE_APP_URL_BACKEND,
  headers: {
      'Authorization': kopapi_bearer_token
  }
});
window.axiosKpapi = axiosKpapi;
const emitter = mitt();

let app = createApp(App)
//app.config.globalProperties.emitter = emitter;
export const bus = emitter;

app.use(store)
app.use(router)
app.component('FilePond', FilePond);
app.mount('#app')
