const parpols = {
    namespaced: true,
    state: {
      // ACTIVE
      activeParpol: [],
  
      // LIST
      listParpols: [],
  
      // SHOW
      showFormParpol: false,
    },
    getters: {
      getShowFormParpol: (state) => {
        return state.showFormParpol;
      },
      getActiveParpol: (state) => {
        return state.activeParpol;
      },
      getActiveParpolManage: (state) => {
        return state.activeParpol;
      },
      getListParpol: (state) => {
        return state.listParpol;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormParpol(state, value) {
        state.showFormParpol = value;
      },
      setActiveParpolManage(state, value) {
        state.activeParpol = value;
      },  
      setListParpol(state, value) {
        state.listParpol = value;
      },  
     },
    actions: { }
  }
  
  export default parpols;