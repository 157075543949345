<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div ref="calendar" class="app-fullcalendar"></div>
                    </div>
                </div>
            </div>            
            <!-- Modal Agenda -->
            <CalendarEventModal></CalendarEventModal>     
        </div>
    </div>
</template>
<script>
import { formatDateUnixToHuman } from '../helpers/global';
import CalendarEventModal from './modal/CalendarEventModal';
import { bus } from "../main";

export default {
    components: { CalendarEventModal },
    name: 'Agenda',
    data() {
        return {
            listAgenda:"",
            listCategory:"",
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        handleDateClick(event) {
            let vm = this;
            let data = {
                category : vm.listCategory
            }
            if(!event.title) {
                data.tanggal = event.target.getAttribute('data-date');
            }
            else {
                data.id = event.id;
                data.tanggal = event.start;
                data.categoryId = event.className;
                data.title = event.title;
            }
            bus.emit("openCalendarEventModal",data);
        },
        initData() {
            let vm = this;
            vm.getKategori();
        },
        getKategori() {
            let vm = this;
            vm.payload = {
                query : `query {
                    getCategorys(
                        tipe: "agenda"
                    )
                    {
                        id
                        tipe
                        color
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listCategory = response.data.data.getCategorys;
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        getAgenda() {
            let vm = this;
            vm.payload = {
                query : `query {
                    getAgendas
                    {
                        id
                        nama
                        waktu
                        status
                        Category {
                            color
                            id
                        }
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    const resdata = response.data.data.getAgendas;
                    vm.listAgenda = vm.mappedDatas(resdata);
                    $(vm.$refs.calendar).fullCalendar({
                        locale: 'id',
                        timeFormat: 'HH:mm',
                        defaultView: 'month',
                        editable: !0,
                        events: vm.listAgenda,
                        header: {
                            left: "prev,next today",
                            center: "title",
                            right: "month,agendaWeek,agendaDay"
                        },
                        // views: {
                        //     agendaWeek: {
                        //         titleFormat: 'D MMMM YYYY', // Format judul "weekday"
                        //         columnFormat: 'dddd, D MMMM YYYY', // Format kolom hari "weekday"
                        //         rowFormat: 'HH:mm', // Format kolom hari "weekday"
                        //     }
                        // },                        
                        eventClick: (calEvent, jsEvent, view) => {
                            vm.handleDateClick(calEvent, jsEvent, view);
                        },
                    });
                    $(vm.$refs.calendar).on('click', '.fc-day', vm.handleDateClick);      
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        mappedDatas(data) {
            return _.map(data, (item) => ({
                id: item.id,
                title: item.nama,
                start: JSON.parse(formatDateUnixToHuman(item.waktu * 1 * 1000,"yyyy-MM-dd HH:mm:ssXXX")),
                end: JSON.parse(formatDateUnixToHuman(item.waktu * 1 * 1000,"yyyy-MM-dd HH:mm:ssXXX")),
                className : item.Category.color
            }));
        },
    },
    computed: {
    },
    mounted() {
      let vm = this;
      vm.initData();
      this.$nextTick(function() {
        vm.getAgenda();
      });
    },
    created() {
      const vm = this;
    },    
};
</script>