<template>
    <div class="form-group col-md-6">
        <label>Template</label>
            <file-pond
                ref="pond"
                name="files"
                label-idle="Browse"
                accepted-file-types="image/jpeg, image/jpg, text/html"
                max-file-size="1MB"
                image-edit-allow-edit=true
                v-on:init="handleFilePondInit"
                v-on:processfile="handleFileProcessed($event)"
                v-on:error="fileTypeNotValid($event)"
                v-on:removefile="removeFile"
                v-bind:image-edit-editor="editor"
                :server="url"
                credits=false
                allowDownloadByUrl=true
                labelMaxFileSizeExceeded="File terlalu besar"
                labelMaxFileSize="Ukuran file maksimum adalah {filesize}"
                labelFileTypeNotAllowed="Format file tidak valid"
                labelFileProcessingError="Format file tidak valid"
                fileValidateTypeLabelExpectedTypes="Format {allButLastType} atau {lastType}"
            />
    </div>
</template>
<script>
import { bus } from "../../main";

export default {
    name: 'FileSuratField',
    data: function () {
        return { 
            url: process.env.VUE_APP_URL_BACKEND + "/api/cek",
            myFiles: null ,
            editor : "editor",
            temp : null //variable for observerd check and the value is "ok" and null
        };
    },
    methods: {
        handleFilePondInit: function () {
            console.log("a1");
        },
        fileTypeNotValid(message){
            console.log("b1");
            let message_error = "Type File Upload Anda Tidak Valid"

            if (message.main) {
                message_error = message.main
            }

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message_error,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$refs.pond.removeFile()
                }
            });
        },
        handleFileProcessed(){
            let file = this.$refs.pond.getFiles()
            this.myFiles = file[0].file;
            this.temp = "ok"
            const datasend = {
                file : this.myFiles,
            }
            console.log("datasend ",datasend);
            bus.emit("openTemplateSurat",datasend);
        },
        removeFile(){
            console.log("test2");
            this.myFiles = null
            this.temp = null
        },
    }
}
</script>
