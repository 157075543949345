export default {
  state: {
    loggedInUser:
      localStorage.getItem("token") != null
        ? JSON.parse(localStorage.getItem("token"))
        : null,
    loading: false,
    error: null
  },
  getters: {
  },
  mutations: {
    setLogout(state) {
        state.loggedInUser = null;
        state.loading = false;
        state.error = null;
    },  
  },
  actions: {
    signOut({commit}) {
      localStorage.removeItem("token");
      localStorage.removeItem("menu");
      localStorage.removeItem("profile");
      localStorage.removeItem("category");
      localStorage.removeItem("propinsi");
      commit("setLogout");
    },
  }
};
