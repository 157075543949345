<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-6 col-xxl-12">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Dashboard'
};
</script>