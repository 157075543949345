<template>
    <div class="form-group col-md-6">
        <label>Tps</label>
        <select id="inputState" class="form-control" 
            v-model="ttpsId"
            @change="onChange($event)"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listTps"
                :key="list.id"
                :value="list.id"
                >{{ list.nama }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'TpsField',
    props : ['tpsId'],
    data() {
        return {
            listTps:"",
            ttpsId:"",
            disableInput: true,
        }
    },
    methods: {
        mappedDatas(data) {
            const res = _.map(data, item =>
                {
                    return {
                        id: item.id + "_" + item.propinsiId + "_" + item.kabupatenId,
                        nama: item.nama,
                    }
                });
            return res;
        },
        onChange(event) {
            const tpsId = event.target.value;
            const datasend = {
                tpsId : tpsId
            }
            bus.emit("openSaksiSuara",datasend);
        },
        initData() {
            let vm = this;
            vm.getData();            
        },
        getData() {
            let vm = this;
            const payload = {
                query : `query {
                    getTps
                    {
                        id
                        nama
                        propinsiId
                        kabupatenId
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    const resdata = response.data.data.getTps;
                    vm.listTps = vm.mappedDatas(resdata);
                }
            })
            .catch((error) => {
                console.log("error-tpsField",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        if(!!vm.tpsId) {
            vm.ttpsId = vm.tpsId;
        }
    },
    created() {
        let vm = this;
        vm.initData();
        bus.on("openTpsField", (data) => {
            console.log("data-tps-field ",JSON.stringify(data));
            vm.ttpsId = data.tpsId;
        });
    },
}
</script>