<template>
    <div class="deznav">
        <div class="deznav-scroll">
            <ul class="metismenu" id="menu">
                <li v-for="menuItem in menuitems" :key="menuItem.id">
                    <a v-if="menuItem.children.length > 0" class="has-arrow ai-icon" aria-expanded="true">
                        <i :class="menuItem.icon"></i>
                            <span class="nav-text">{{ menuItem.nama }}</span>
                    </a>
                    <a v-else :href="menuItem.url">
                        <i :class="menuItem.icon"></i>
                            {{ menuItem.nama }}
                    </a>
                    <ul v-if="menuItem.children.length > 0">
                        <li v-for="submenu in menuItem.children" :key="submenu.id">
                            <a v-if="submenu.children.length > 0" class="has-arrow ai-icon"  aria-expanded="true">
                                <i :class="submenu.icon"></i>
                                    <span class="nav-text">{{ submenu.nama }}</span>
                            </a>
                            <a v-else :href="submenu.url">
                                <i :class="submenu.icon"></i>
                                    <span>{{ submenu.nama }}</span>
                            </a>
                            <ul v-if="submenu.children.length > 0">
                                <li v-for="subsubmenu in submenu.children" :key="subsubmenu.id">
                                    <a :href="subsubmenu.url">
                                        <i :class="subsubmenu.icon"></i>
                                           &nbsp;&nbsp;&nbsp;{{ subsubmenu.nama }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    name: 'Sidebar',
    data() {
        return {
            menuitems: []
        }
    },
    methods: {
        buildMenuHierarchy(menuItems,parentId) {
            return _.filter(menuItems, (menuItem) => {
                if (menuItem.parent === parentId) {
                    menuItem.children = this.buildMenuHierarchy(menuItems, menuItem.id);
                    return true;
                }            
                return false;
            });
        },            
    },
    computed: {
    },
    created() {
        const menu = JSON.parse(localStorage.getItem("menu"));  
        this.menuitems = this.buildMenuHierarchy(menu,"#");
    }
};
</script>