export function category() {
    const payload = {
        query : `query {
            getCategorys(
                tipe: "template"
            )
            {
                id
            }
        }`
    }
    const config = {
        method: "POST",
        url: "/graphql",
        data : payload
    }
    axiosKpapi(config)
    .then((response) => {
        if (response.data) {
            localStorage.setItem("category", JSON.stringify(response.data.data.getCategorys));
        }
    })
    .catch((error) => {
        console.log("error",error.message);
    });
}

export function organisasi() {
    const payload = {
        query : `query {
            getOrgs
            {
                id
            }
        }`
    }
    const config = {
        method: "POST",
        url: "/graphql",
        data : payload
    }
    axiosKpapi(config)
    .then((response) => {
        if (response.data) {
            localStorage.setItem("organisasi", JSON.stringify(response.data.data.getOrgs));
        }
    })
    .catch((error) => {
        console.log("error",error.message);
    });
}

export function jenissurat() {
    const payload = {
        query : `query {
            getJenisSurats
            {
                id
            }
        }`
    }
    const config = {
        method: "POST",
        url: "/graphql",
        data : payload
    }
    axiosKpapi(config)
    .then((response) => {
        if (response.data) {
            localStorage.setItem("jenissurat", JSON.stringify(response.data.data.getJenisSurats));
        }
    })
    .catch((error) => {
        console.log("error",error.message);
    });
}

export function propinsi() {
    const payload = {
        query : `query {
            getPropinsis
            {
                id
                nama
            }
        }`
    }
    const config = {
        method: "POST",
        url: "/graphql",
        data : payload
    }
    axiosKpapi(config)
    .then((response) => {
        if (response.data) {
            localStorage.setItem("propinsi", JSON.stringify(response.data.data.getPropinsis));
        }
    })
    .catch((error) => {
        console.log("error",error.message);
    });
}

export function kommand() {
    const payload = {
        query : `query {
            getCommands
            {
                id
                ket
            }
        }`
    }
    const config = {
        method: "POST",
        url: "/graphql",
        data : payload
    }
    axiosKpapi(config)
    .then((response) => {
        if (response.data) {
            localStorage.setItem("kommand", JSON.stringify(response.data.data.getCommands));
        }
    })
    .catch((error) => {
        console.log("error",error.message);
    });    
}