<template>
    <div class="modal fade none-border" id="data-detail">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><strong>Details</strong></h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="control-label">Kategori:</label>
                            <input :disabled="disableInput" type="text" class="form-control" v-model="form.kategori">
                        </div>
                        <div class="col-md-6">
                            <label class="control-label">Nama:</label>
                            <input :disabled="disableInput" type="text" class="form-control" v-model="form.nama">
                        </div>
                        <div class="col-md-10">
                            <label class="control-label">Template:</label>
                            <div v-html="form.template"></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { bus } from "../../main";
export default {
    name: 'TemplatePreviewModal',
    data() {
        return {
            form: {
                kategori : "",
                nama : "",
                template : ""
            },
            disableInput: true,
        }
    },
    methods: {
    },
    created() {
        let vm = this; 
        bus.on("openTemplatePreviewModal", (data) => {
            vm.form.kategori = data.categoryId;
            vm.form.nama = data.nama;
            vm.form.template = atob(data.template);
            const params = JSON.parse(data.body);
            let counter = 1;
            let paramreplace;
            for (const data of params.example.body_text) {
                let key = "{{" + counter + "}}";
                if(data=="tanggal") {
                    paramreplace = new Date();
                }
                vm.form.template = vm.form.template.replace(key,paramreplace);
                counter++;
            }
        });
    },
}
</script>