<template>
    <div class="form-group col-md-6">
        <label>Desa</label>
        <select id="inputState" class="form-control" 
            v-model="dsId"
            @change="onChange($event)"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listDesa"
                :key="list.id"
                :value="list.id"
                >{{ list.nama }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'DesaField',
    props:[
    "desaId",
    "kecId"
  ],
    data() {
        return {
            listDesa:"",
            dsId:"",
            disableInput: true,
        }
    },
    methods: {
        onChange(event) {
            const desaId = event.target.value;
            const senddata = {
                desaId: desaId,
                kecamatanId: "",
                kabupatenId: "",
                propinsiId: ""
            }
            bus.emit("openTemplateSurat",senddata);
            bus.emit("openOrg",senddata);
            bus.emit("openSaksiSuara",senddata);
        },
        initData() {
            let vm = this;
            const desaId = vm.desaId;
            const kecId = vm.kecId;
            vm.dsId = desaId;
            vm.getData(kecId);
        },
        getData(kecamatanId) {
            let vm = this;
            const payload = {
                query : `query {
                    getDesas(
                        kecamatanId : ${kecamatanId}
                    )
                    {
                        id
                        nama
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listDesa = response.data.data.getDesas;
                }
            })
            .catch((error) => {
                console.log("error",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        if(!!vm.kecId && !!vm.desaId) {
            vm.kecamatanId = vm.kecId;
            vm.dsId = vm.desaId;
        }
    },
    created() {
        let vm = this; 
        vm.initData();
        bus.on("openDesaField", (data) => {
            vm.dsId = data.desaId
            vm.getData(data.kecamatanId);
        });
    },
}
</script>