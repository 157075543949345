const reminders = {
    namespaced: true,
    state: {
      // ACTIVE
      activeReminder: [],
  
      // LIST
      listReminders: [],
  
      // SHOW
      showFormReminder: false,
    },
    getters: {
      getShowFormReminder: (state) => {
        return state.showFormReminder;
      },
      getActiveReminder: (state) => {
        return state.activeReminder;
      },
      getActiveReminderManage: (state) => {
        return state.activeReminder;
      },
      getListReminder: (state) => {
        return state.listReminder;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormReminder(state, value) {
        state.showFormReminder = value;
      },
      setActiveReminderManage(state, value) {
        state.activeReminder = value;
      },  
      setListReminder(state, value) {
        state.listReminder = value;
      },  
     },
    actions: { }
  }
  
  export default reminders;