import { createRouter, createWebHistory } from 'vue-router'
import { isValidJwt } from '../helpers/global';

import home from "../views/home.vue";
import PageCustomer from "../views/pages/PageCustomer.vue";
import PageParpol from "../views/pages/PageParpol.vue";
import PagePropinsi from "../views/pages/PagePropinsi.vue";
import PageTemplate from "../views/pages/PageTemplate.vue";
import PageSuratTemplate from "../views/pages/PageSuratTemplate.vue";
import PageOrganisasi from "../views/pages/PageOrganisasi.vue";
import PageAgenda from "../views/pages/PageAgenda.vue";
import login from "../views/login.vue";
import Onboarding from "../views/Onboarding.vue";
import PageReminder from "../views/pages/PageReminder.vue";
import Page from "../views/Page.vue";
import PageDpt from "../views/pages/PageDpt.vue";
import PageSaksiSuara from "../views/pages/PageSaksiSuara.vue";
import PageCanvas from "../views/pages/PageCanvas.vue";
import PageProfile from "../views/pages/PageProfile.vue";
import PageKeyword from "../views/pages/PageKeyword.vue";
import PageSubscribe from "../views/pages/PageSubscribe.vue";
import PageCommand from "../views/pages/PageCommand.vue";
import PageChangePassword from "../views/pages/PageChangePassword.vue";
import PageUser from "../views/pages/PageUser.vue";
import PageTps from "../views/pages/PageTps.vue";

// import { Home, Login } from '@/views';

const routes  = [
    {
        path: '/',
        component: Page,
        children: [
            {
                path: '',
                name: 'home',
                component: home,
                auth: true
            },
            {
                path: 'customer',
                name: 'PageCustomer',
                component: PageCustomer,
                auth: true
            },
            {
                path: 'parpol',
                name: 'PageParpol',
                component: PageParpol,
                auth: true
            },
            {
                path: 'propinsi',
                name: 'PagePropinsi',
                component: PagePropinsi,
                auth: true
            },
            {
                path: 'mtemplate',
                name: 'PageTemplate',
                component: PageTemplate,
                auth: true
            },
            {
                path: 'mtemplatesurat',
                name: 'PageSuratTemplate',
                component: PageSuratTemplate,
                auth: true
            },
            {
                path: 'organisasi',
                name: 'PageOrganisasi',
                component: PageOrganisasi,
                auth: true
            },
            {
                path: 'agenda',
                name: 'PageAgenda',
                component: PageAgenda,
                auth: true
            },
            {
                path: 'reminder',
                name: 'PageReminder',
                component: PageReminder,
                auth: true
            },
            {
                path: 'dpt',
                name: 'DptPemilih',
                component: PageDpt,
                auth: true
            },
            {
                path: 'saksisuara',
                name: 'SaksiSuara',
                component: PageSaksiSuara,
                auth: true
            },
            {
                path: 'tps',
                name: 'Tps',
                component: PageTps,
                auth: true
            },
            {
                path: 'user',
                name: 'User',
                component: PageUser,
                auth: true
            },
            {
                path: 'canvas',
                name: 'Canvas',
                component: PageCanvas,
                auth: true
            },
            {
                path: 'profile',
                name: 'Profile',
                component: PageProfile,
                auth: true
            },
            {
                path: 'keyword',
                name: 'Keyword',
                component: PageKeyword,
                auth: true
            },
            {
                path: 'subscribe',
                name: 'Subscribe',
                component: PageSubscribe,
                auth: true
            },
            {
                path: 'command',
                name: 'Command',
                component: PageCommand,
                auth: true
            },
            {
                path: 'changepassword',
                name: 'ChangePassword',
                component: PageChangePassword,
                auth: true
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: Onboarding
    }
];

const router = createRouter({
    history : createWebHistory(),
    routes
})
export default router;
router.afterEach(() => {
    const token = localStorage.getItem("token");
    if (token==null) {
        const url = window.location.href;
        if(!url.includes("login") && !url.includes("onboarding")) {
            location.replace("/login")
        }
    }
    else {
        const resultjwt = isValidJwt(token)
        if(resultjwt==false) {
            localStorage.removeItem("token");            
            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: "Token Expired",
                confirmButtonText: 'Ok'
            }).then((result) => {
                window.location.href = `/`;
            });
        }
    }
})