const customers = {
    namespaced: true,
    state: {
      // ACTIVE
      activeCustomer: [],
  
      // LIST
      listCustomers: [],
  
      // SHOW
      showFormCustomer: false,
    },
    getters: {
      getShowFormCustomer: (state) => {
        return state.showFormCustomer;
      },
      getActiveCustomer: (state) => {
        return state.activeCustomer;
      },
      getActiveCustomerManage: (state) => {
        return state.activeCustomer;
      },
      getListCustomer: (state) => {
        return state.listCustomer;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormCustomer(state, value) {
        state.showFormCustomer = value;
      },
      setActiveCustomerManage(state, value) {
        state.activeCustomer = value;
      },  
      setListCustomer(state, value) {
        state.listCustomer = value;
      },  
     },
    actions: { }
  }
  
  export default customers;