const canvass = {
    namespaced: true,
    state: {
      // ACTIVE
      activeCanvas: [],
  
      // LIST
      listCanvass: [],
  
      // SHOW
      showFormCanvas: false,
    },
    getters: {
      getShowFormCanvas: (state) => {
        return state.showFormCanvas;
      },
      getActiveCanvas: (state) => {
        return state.activeCanvas;
      },
      getActiveCanvasManage: (state) => {
        return state.activeCanvas;
      },
      getListCanvas: (state) => {
        return state.listCanvas;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormCanvas(state, value) {
        state.showFormCanvas = value;
      },
      setActiveCanvasManage(state, value) {
        state.activeCanvas = value;
      },  
      setListCanvas(state, value) {
        state.listCanvas = value;
      },  
     },
    actions: { }
  }
  
  export default canvass;