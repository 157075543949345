const subscribes = {
    namespaced: true,
    state: {
      // ACTIVE
      activeSubscribe: [],
  
      // LIST
      listSubscribes: [],
  
      // SHOW
      showFormSubscribe: false,
    },
    getters: {
      getShowFormSubscribe: (state) => {
        return state.showFormSubscribe;
      },
      getActiveSubscribe: (state) => {
        return state.activeSubscribe;
      },
      getActiveSubscribeManage: (state) => {
        return state.activeSubscribe;
      },
      getListSubscribe: (state) => {
        return state.listSubscribe;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormSubscribe(state, value) {
        state.showFormSubscribe = value;
      },
      setActiveSubscribeManage(state, value) {
        state.activeSubscribe = value;
      },  
      setListSubscribe(state, value) {
        state.listSubscribe = value;
      },  
     },
    actions: { }
  }
  
  export default subscribes;