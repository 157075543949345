const saksisuaras = {
    namespaced: true,
    state: {
      // ACTIVE
      activeSaksiSuara: [],
  
      // LIST
      listSaksiSuaras: [],
  
      // SHOW
      showFormSaksiSuara: false,
    },
    getters: {
      getShowFormSaksiSuara: (state) => {
        return state.showFormSaksiSuara;
      },
      getActiveSaksiSuara: (state) => {
        return state.activeSaksiSuara;
      },
      getActiveSaksiSuaraManage: (state) => {
        return state.activeSaksiSuara;
      },
      getListSaksiSuara: (state) => {
        return state.listSaksiSuara;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormSaksiSuara(state, value) {
        state.showFormSaksiSuara = value;
      },
      setActiveSaksiSuaraManage(state, value) {
        state.activeSaksiSuara = value;
      },  
      setListSaksiSuara(state, value) {
        state.listSaksiSuara = value;
      },  
     },
    actions: { }
  }
  
  export default saksisuaras;