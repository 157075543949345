import VueJwtDecode from 'vue-jwt-decode';

import { format, parse, parseISO  } from 'date-fns';

export function formatDateUnixToHuman(unixTime,pattern) {
  return format(new Date(unixTime * 1),  `"${pattern}"`);
}

export function formatHumanToDateUnix(dateTime) {
  const parsedDateTime = parse(dateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
  return Math.floor(parsedDateTime.getTime() / 1000);
}
export function formatUTCDateToLocal(dateTime,pattern) {
  return format(parseISO(dateTime), `"${pattern}"`,
  {
    timeZone: 'Asia/Jakarta'
  });
}

export function parseJwt (token) {
  const tokenJwt = VueJwtDecode.decode(token);
  return tokenJwt;
}

export const isValidJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  const jwtPayload = JSON.parse(jsonPayload);
  if (jwtPayload.exp < Date.now()/1000) {
      return false;
  }
  else {
      return true;
  }
}

export function findMatch (data,datamatch) {
  const regex = new RegExp(datamatch, 'g');  
  if(regex.test(data)) {
    return true;
  }
  else
    return false;
}
