<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="col-md-12 text-right">
                            <button type="button" class="btn btn-success" @click="add()">
                                <span class="btn-icon-left text-info">
                                    <i class="fa fa-plus color-info"></i>
                                </span>Add
                            </button>
                        </div>                        
                    </div>                    
                    <div class="table-responsive">
                        <table ref="parpoldatatable" class="display min-w850">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="checkAll" required="">
                                            <label class="custom-control-label" for="checkAll"></label>
                                        </div>
                                    </th>
                                    <th>ID</th>
                                    <th>Nama</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(parpol, index) in listParpol" :key="parpol.id">
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheckBox2" required="">
                                            <label class="custom-control-label" for="customCheckBox2"></label>
                                        </div>
                                    </td>
                                    <td>{{ parpol.id }}</td>
                                    <td>{{ parpol.nama }}</td>
                                    <td>
                                        <span class="badge light badge-danger">
                                            <i v-if="parpol.status.toLowerCase()=='draft'" class="fa fa-circle text-warning mr-1"></i>
                                            <i v-else-if="parpol.status.toLowerCase()=='active'" class="fa fa-circle text-success mr-1"></i>
                                            <i v-else-if="parpol.status.toLowerCase()=='reject'" class="fa fa-circle text-danger mr-1"></i>
                                            {{ parpol.status }}
                                        </span>
                                    </td>
                                    <td>
                                        <div class="form-row">
                                            <div class="d-flex">
                                                <a href="#" class="btn btn-primary shadow btn-xs sharp mr-1" @click="edit(parpol)"><i class="fa fa-pencil"></i></a>
                                                <a href="#" class="btn btn-danger shadow btn-xs sharp" @click="status(parpol,`delete`)"><i class="fa fa-trash"></i></a>
                                            </div>

                                            <div class="dropdown ml-auto text-right">
                                                <div class="btn-link" data-toggle="dropdown">
                                                    <svg width="50px" height="50px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="50" height="50"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                                </div>
                                                <ul class="dropdown-menu dropdown-menu-right">
                                                    <li class="dropdown-item" ><a href="#" @click="status(parpol,`active`)">Active</a></li>
                                                    <li class="dropdown-item"><a href="#" @click="status(parpol,`reject`)">Reject</a></li>
                                                    <li class="dropdown-item"><a href="#" @click="status(parpol,`draft`)">Draft</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name: 'ParpolList',
    data() {
        return {
            table: "",
            url:"/graphql",
            payload: ""  
        }
    },
    watch: {
        listParpol(nv, ov) {
            if (nv.length == 0) return;
            this.$nextTick (() => { 
                if (nv.length > 10) {
                    this.table = table.row( this ).data();
                }
            })
        }
    },
    methods: {
        add() {
            this.isFormParpolActive = true;
            this.activeParpol = {};
        },
        edit(data) {
            let vm = this;
            vm.isFormParpolActive = true;
            vm.activeParpol = data;
        },
        status(data,status) {
            let vm = this;
            if(status=="delete") {
                vm.payload = {
                    query : 
                    `
                        mutation {    
                            deleteParpol(id : "${data.id}")
                            {
                                id
                                nama
                                status
                            }
                        }                
                    `
                }
                Swal.fire({
                    title: "Are you sure ?",
                    text: "You want to delete",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const config = {
                            method: "POST",
                            url: vm.url,
                            data : vm.payload
                        }
                        axiosKpapi(config)
                        .then((response) => {
                            if (response.data.data) {
                                Swal.fire({
                                icon: 'success',
                                title: 'Yeay...',
                                text: "Success",
                                confirmButtonText: 'Ok'
                                }).then((result) => {
                                    window.location.reload();
                                })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops!',
                                    text: JSON.stringify(response.data.errors),
                                });
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: error.message,
                            });
                        });
                    }
                });
            }
            else {
                vm.payload = {
                    query : 
                    `
                        mutation {    
                            updateParpol(id : "${data.id}"
                                input: {
                                    status: "${status.toUpperCase()}"
                                }
                            )
                            {
                                id
                                nama
                                status
                            }
                        }                
                    `
                }
                const config = {
                    method: "POST",
                    url: vm.url,
                    data : vm.payload
                }
                axiosKpapi(config)
                .then((response) => {
                    if (response.data.data) {
                        Swal.fire({
                        icon: 'success',
                        title: 'Yeay...',
                        text: "Success",
                        confirmButtonText: 'Ok'
                        }).then((result) => {
                            window.location.reload();
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: JSON.stringify(response.data.errors),
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                    icon: 'error',
                    title: 'Oops!',
                    text: error.message,
                    });
                });
            }
        },
        initData() {
            let vm = this;
            vm.payload = {
                query : `query {
                    getParpols
                    {
                        id
                        nama
                        status
                    }
                }`
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listParpol = response.data.data.getParpols;
                    this.$nextTick(function() {
                        $(this.$refs.parpoldatatable).DataTable({
                            "paging": true,        // Aktifkan paging
                            "pageLength": 10,      // Jumlah baris per halaman
                            "lengthChange": true, // Tampilkan atau sembunyikan dropdown ganti jumlah baris            
                        });
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        }
    },
    computed: {
        activeParpol: {
            get() {
                return this.$store.getters['parpols/getActiveParpolManage'];
            },
            set(value) {
                this.$store.commit('parpols/setActiveParpolManage', value);
            }
        },
        isFormParpolActive: {
            get() {
                return this.$store.getters['parpols/getShowFormParpol'];
            },
            set(value) {
                this.$store.commit('parpols/setShowFormParpol', value);
            }
        },
        listParpol: {
            get() {
                return this.$store.getters['parpols/getListParpol'];
            },
            set(value) {
                this.$store.commit('parpols/setListParpol', value);
            }
        },
    },
    mounted() {
    },
    created() {
      const vm = this;
      if (vm.listParpol==undefined) vm.initData();      
    }
};
</script>