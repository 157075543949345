<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-group col-md-6">
                                    <label>Kategori</label>
                                    <select id="inputState" class="form-control default-select" v-model="form.categoryId">
                                        <option value="">Choose...</option>
                                        <option
                                            v-for="list in listcategory"
                                            :key="list.id"
                                            :value="list.id"
                                            >{{ list.id }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Nama</label>
                                    <input type="text" class="form-control" placeholder="Otp" v-model="form.nama">
                                </div>
                                <div class="form-group col-md-20">
                                    <label>Template</label>
                                    <textarea ref="template" v-model="form.template" :maxlength="bodyMaxLength" rows="15" class="form-control w-100" @keyup.stop="handleBodyInput($event,form.body)" :disabled="inputDisabled"></textarea>
                                    <div class="col-md-12 text-right">
                                        <button type="button" class="btn btn-outline" @click="applyStyle('*',form.template)" v-tooltip:top="'Bold'"><i class="fa fa-bold"></i></button>
                                        <button type="button" class="btn btn-outline" @click="applyStyle('_',form.template)" v-tooltip:top="'Italic'"><i class="fa fa-italic"></i></button>
                                        <button type="button" class="btn btn-outline" @click="applyStyle('~',form.template)" v-tooltip:top="'Strikethrough'"><i class="fa fa-strikethrough"></i></button>
                                        <button type="button" class="btn btn-outline" @click="applyStyle('```',form.template)" v-tooltip:top="'Monospace'"><i class="fa fa-code"></i></button>

                                        <button type="button" class="btn btn-outline" @click="addVariable(form)">
                                            <span class="btn-icon-left text-info">
                                                <i class="fa fa-plus"></i>
                                            </span>Add Variable
                                        </button>                                    
                                    </div>
                                </div>
                                <div class="form-group col-md-20" v-if="form.body.example.body_text.length > 0">
                                    <label class="control-label">Parameters</label>
                                    <input type="text" class="form-control mb-2" v-for="(body_text, index) in form.body.example.body_text" v-model="form.body.example.body_text[index]" :placeholder="index + 1" v-bind:key="index"/>
                                </div>

                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { category } from '../helpers/loaddata';
export default {
    name: 'Template',
    data() {
        return {
            idori:"",
            listcategory:"",
            inputDisabled: false,
            headerMaxLength: 60,
            bodyMaxLength: 1024,
            footerMaxLength: 60,
            buttonTextMaxLength: 25,
            phoneNumberMaxLength: 20,
            urlMaxLength: 2000,
            form: {
                categoryId: "",
                nama: "",
                template: "",
                body: {
                    text: "",
                    example : {
                        body_text: []
                    }
                },
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        applyStyle(char, template_body){
        let vm = this;
        let textArea = vm.$refs.template;
            if(textArea){
                let startIndex = textArea.selectionStart;
                let endIndex = textArea.selectionEnd;
                let stringBody = template_body;
                let newStringBody = stringBody.substr(0, startIndex) + char + stringBody.substr(startIndex,endIndex-startIndex) + char + stringBody.substr(endIndex);
                vm.form.template = newStringBody;

                // this.refreshMessageTemplatePreview();
            }
        },
        addVariable(form) {
            let vm = this;
            let matches = this.getExistingVariable(form.template);
            if(form.template.length > 0)
                form.template += " ";
            form.template += "{{" + (matches.length + 1) + "}}";
            vm.form.template = form.template;
            // $(this.$refs.template).summernote('code', form.template);

            matches = this.getExistingVariable(form.template);
            if(form.body.example.body_text.length > matches.length){
                for(let i = form.body.example.body_text.length; i > matches.length; i--){
                    form.template.splice(i-1,1);
                }
            }
            for (let i = 0; i < matches.length; i++) {
                form.body.example.body_text[i] = "{{" + ( i + 1) + "}}";
            }
        },
        handleBodyInput(e,template_body){
            let matches = this.getExistingVariable(e.target.value);
            if(template_body.example.body_text.length > matches.length){
                for(let i = template_body.example.body_text.length; i > matches.length; i--){
                    template_body.example.body_text.splice(i-1,1);
                }
            }
            for (let i = 0; i < matches.length; i++) {
                template_body.example.body_text[i] = "";
            }

            // this.refreshMessageTemplatePreview();
        },
        getExistingVariable(str){
            const regexp = /{{([1-9][0-9]*?)}}/g;
            const matches_string = str.matchAll(regexp);
            const matches = [];

            for (const match of matches_string) {
                matches.push(match);
            }

            return matches;
        },
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            const databody = JSON.stringify(form.body);
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateTemplate(id : "${vm.idori}"
                            input: {
                                nama:"${form.nama}"
                                template:"""${form.template}"""
                                categoryId:"${form.categoryId}"
                                body:"""${databody}"""
                            }
                        )
                        {
                            nama
                            template
                            status
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setTemplate (input : {
                            categoryId: "${form.categoryId}"
                            nama: "${form.nama}"
                            template: """${form.template}"""
                            body:"""${databody}"""
                        })
                        {
                            nama
                            template
                            status
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
            if (localStorage.getItem("category")==null) {
                vm.listcategory = category();
            }
            else {
                vm.listcategory = JSON.parse(localStorage.getItem("category"));
            }
        }   
    },
    computed: {
        activeTemplate: {
            get() {
                return this.$store.getters['templates/getActiveTemplateManage'];
            },
            set(value) {
                this.$store.commit('templates/setActiveTemplateManage', value);
            }
        },
        isFormTemplateActive: {
            get() {
                return this.$store.getters['templates/getShowFormTemplate'];
            },
            set(value) {
                this.$store.commit('templates/setShowFormTemplate', value);
            }
        },
        listTemplate: {
            get() {
                return this.$store.getters['templates/getListTemplate'];
            },
            set(value) {
                this.$store.commit('templates/setListTemplate', value);
            }
        },
    },
    watch: {
        activeTemplate: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                vm.idori = value.id;
                vm.form.id = value.id;
                vm.form.categoryId = value.categoryId;
                vm.form.nama = value.nama;
                vm.form.body = JSON.parse(value.body);
                vm.form.template = value.template;
            }
        }
    },
    mounted() {
      let vm = this;
    },
    created() {
      const vm = this;
      vm.initData();
    },    
};
</script>