const surattemplates = {
    namespaced: true,
    state: {
      // ACTIVE
      activeSuratTemplate: [],
  
      // LIST
      listSuratTemplates: [],
  
      // SHOW
      showFormSuratTemplate: false,
    },
    getters: {
      getShowFormSuratTemplate: (state) => {
        return state.showFormSuratTemplate;
      },
      getActiveSuratTemplate: (state) => {
        return state.activeSuratTemplate;
      },
      getActiveSuratTemplateManage: (state) => {
        return state.activeSuratTemplate;
      },
      getListSuratTemplate: (state) => {
        return state.listSuratTemplate;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormSuratTemplate(state, value) {
        state.showFormSuratTemplate = value;
      },
      setActiveSuratTemplateManage(state, value) {
        state.activeSuratTemplate = value;
      },  
      setListSuratTemplate(state, value) {
        state.listSuratTemplate = value;
      },  
     },
    actions: { }
  }
  
  export default surattemplates;