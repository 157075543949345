<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>
                                            Nama
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="TPS1" v-model="form.nama" @input="autoFillTPS">
                                    </div>
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
import { bus } from "../main";

export default {
    name: 'Tps',
    data() {
        return {
            idori:"",
            kodetpsDisabled: true,            
            form: {
                nama: "",
            },
            url: "/graphql",
            payload: ""  
        }
    },
    methods: {
        back() {
            window.location.reload();
        },
        autoFillCountryCode() {
            const vm = this;
            if (!vm.form.nohp.startsWith('62')) {
                if(vm.form.nohp==0) {
                    vm.form.nohp = '62';
                }
                else {
                    vm.form.nohp = '62'+vm.form.nohp;
                }
            }
            else if(vm.form.nohp=="620") {
                vm.form.nohp = '62'+vm.form.nohp;
            }
        },
        autoFillTPS() {
            const vm = this;
            if (!vm.isNumber(vm.form.nama)) {
                if(vm.form.nama.toLowerCase()=="t" || vm.form.nama.toLowerCase()=="tp") {
                    vm.form.nama = 'TPS';
                }
                else {
                    vm.form.nama = vm.form.nama;
                }
            }
            else {
                vm.form.nama = 'TPS' + vm.form.nama;
            }
        },
        isNumber(value) {
            return /^\d+$/.test(value);
        },
        saveForm(form) {
            let vm = this;
            vm.$nextTick()
            .then(() => {
                // check validation
                return vm.FormValidation();
            })
            .then((validation) => {
                if (validation) {
                    vm.submit(form)
                }
            });
        },
        submit(form) {
            let vm = this;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updateTps(id : "${vm.idori}"
                            input: {
                                nama: "${form.nama}"
                            }
                        )
                        {
                            nama
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setTps (input : {
                            nama: "${form.nama}"
                        })
                        {
                            nama
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if(response.data.errors) {
                    const msgerror = response.data.errors;
                    let msg = msgerror[0].message;
                    const regex = /Validation error/gi; // Ekspresi reguler untuk mencocokkan "validation error" (tidak peduli huruf besar/kecil)
                    const matches = msgerror[0].message.match(regex);
                    if (matches) {
                        msg = `${vm.form.nama} sudah ada`;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops!',
                            text: msg,
                        });
                    }
                }
                else {

                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        },
        initData() {
            let vm = this;
        },
        FormValidation() {
            let vm = this, err_message = "";
            if (_.isEmpty(vm.form.nama)) {
                err_message += "- Nama Kosong<br>";
            }
            if (!_.isEmpty(err_message)) {
                Swal.fire({
                  icon: 'error',
                  title: 'Save Failed',
                  html: err_message
                });
                return false;
            }
            return true;
        },
    },
    computed: {
        activeTp: {
            get() {
                return this.$store.getters['tps/getActiveTpManage'];
            },
            set(value) {
                this.$store.commit('tps/setActiveTpManage', value);
            }
        },
        isFormTpActive: {
            get() {
                return this.$store.getters['tps/getShowFormTp'];
            },
            set(value) {
                this.$store.commit('tps/setShowFormTp', value);
            }
        },
        listTp: {
            get() {
                return this.$store.getters['tps/getListTp'];
            },
            set(value) {
                this.$store.commit('tps/setListTp', value);
            }
        },
    },
    watch: {
        activeTp: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                vm.idori                = value.id;
                vm.form.nama            = value.nama;
            }
        }
    },
    created() {
      const vm = this;
      vm.initData();
    },    
};
</script>