<template>
    <div class="form-group col-md-6">
        <label>Kecamatan</label>
        <select id="inputState" class="form-control" 
            v-model="kecamatanId"
            @change="onChange($event)"
        >
            <option selected>Choose...</option>
            <option
                v-for="list in listKecamatan"
                :key="list.id"
                :value="list.id"
                >{{ list.nama }}
            </option>
        </select>
    </div>

</template>
<script>
import { bus } from "../../main";

export default {
    name: 'KecamatanField',
    props:[
    "kabId",
    "kecId"
  ],
    data() {
        return {
            listKecamatan:"",
            kecamatanId:"",
            kabupatenselId:"",
            disableInput: true,
        }
    },
    methods: {
        onChange(event) {
            const kecamatanId = event.target.value;
            const senddata = {
                desaId: "",
                kecamatanId: kecamatanId,
                kabupatenId: "",
                propinsiId: ""
            }
            bus.emit("openSaksiSuara",senddata);
            bus.emit("openOrg",senddata);
            bus.emit("openTemplateSurat",senddata);
            bus.emit("openDesaField",senddata);
        },
        initData() {
            let vm = this;
            const kecId = vm.kecId;
            const kabId = vm.kabId;
            vm.kecamatanId = kecId;
            vm.getData(kabId);
        },
        getData(kabupatenId) {
            let vm = this;
            const payload = {
                query : `query {
                    getKecamatans(
                        kabupatenId : ${kabupatenId}
                    )
                    {
                        id
                        nama
                    }
                }`
            }
            const config = {
                method: "POST",
                url: "/graphql",
                data : payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data) {
                    vm.listKecamatan = response.data.data.getKecamatans;
                }
            })
            .catch((error) => {
                console.log("error",error.message);
            });
        }
    },
    mounted() {   
        let vm = this; 
        if(!!vm.kabId && !!vm.kecId) {
            vm.kabupatenId = vm.kabId;
            vm.kecamatanId = vm.kecId;
        }
    },
    created() {
        let vm = this; 
        vm.initData();
        bus.on("openKecamatanField", (data) => {
            vm.kecamatanId = data.kecamatanId
            vm.getData(data.kabupatenId);
        });
    },
}
</script>