const users = {
    namespaced: true,
    state: {
      // ACTIVE
      activeUser: [],
  
      // LIST
      listUsers: [],
  
      // SHOW
      showFormUser: false,
    },
    getters: {
      getShowFormUser: (state) => {
        return state.showFormUser;
      },
      getActiveUser: (state) => {
        return state.activeUser;
      },
      getActiveUserManage: (state) => {
        return state.activeUser;
      },
      getListUser: (state) => {
        return state.listUser;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormUser(state, value) {
        state.showFormUser = value;
      },
      setActiveUserManage(state, value) {
        state.activeUser = value;
      },  
      setListUser(state, value) {
        state.listUser = value;
      },  
     },
    actions: { }
  }
  
  export default users;