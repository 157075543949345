<template>
    <!-- row -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-16 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-group col-md-6">
                                    <label>ID</label>
                                    <input type="text" class="form-control" placeholder="13" v-model="form.id">
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Nama</label>
                                    <input type="text" class="form-control" placeholder="Jawa Tengah" v-model="form.nama">
                                </div>
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn mr-2 btn-primary" @click="saveForm(form)">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-save color-info"></i>
                                        </span>Save
                                    </button>
                                    <button type="button" class="btn btn-light" @click="back()">
                                        <span class="btn-icon-left text-info">
                                            <i class="fa fa-cancel color-info"></i>
                                        </span>Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {
    name: 'Propinsi',
    data() {
        return {
            idori:"",
            form: {
                id: "",
                nama: "",
            },
            url: "/graphql",
            payload: ""  
        }
    },
    computed: {
        activePropinsi: {
            get() {
                return this.$store.getters['propinsis/getActivePropinsiManage'];
            },
            set(value) {
                this.$store.commit('propinsis/setActivePropinsiManage', value);
            }
        },
        isFormPropinsiActive: {
            get() {
                return this.$store.getters['propinsis/getShowFormPropinsi'];
            },
            set(value) {
                this.$store.commit('propinsis/setShowFormPropinsi', value);
            }
        },
        listPropinsi: {
            get() {
                return this.$store.getters['propinsis/getListPropinsi'];
            },
            set(value) {
                this.$store.commit('propinsis/setListPropinsi', value);
            }
        },
    },
    methods: {
        back() {
            window.location.reload();
        },
        saveForm(form) {
            let vm = this;
            if(vm.idori!="") {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        updatePropinsi(id : "${vm.idori}"
                            input: {
                                id: "${form.id}"
                                nama: "${form.nama}"
                            }
                        )
                        {
                            id
                            nama
                            status
                        }
                    }                
                    `
                }
            }
            else {
                vm.payload = {
                    query : 
                    `
                    mutation {    
                        setPropinsi (input : {
                            id: "${form.id}"
                            nama: "${form.nama}"
                        })
                        {
                            id
                            nama
                            status
                        }
                    }                    
                    `
                }
            }
            const config = {
                method: "POST",
                url: vm.url,
                data : vm.payload
            }
            axiosKpapi(config)
            .then((response) => {
                if (response.data.data) {
                    Swal.fire({
                    icon: 'success',
                    title: 'Yeay...',
                    text: "Success",
                    confirmButtonText: 'Ok'
                    }).then((result) => {
                        window.location.reload();
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops!',
                        text: JSON.stringify(response.data.errors),
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops!',
                  text: error.message,
                });
            });
        }     
    },
    computed: {
        activePropinsi: {
            get() {
                return this.$store.getters['propinsis/getActivePropinsiManage'];
            },
            set(value) {
                this.$store.commit('propinsis/setActivePropinsiManage', value);
            }
        },
        isFormPropinsiActive: {
            get() {
                return this.$store.getters['propinsis/getShowFormPropinsi'];
            },
            set(value) {
                this.$store.commit('propinsis/setShowFormPropinsi', value);
            }
        },
        listPropinsi: {
            get() {
                return this.$store.getters['propinsis/getListPropinsi'];
            },
            set(value) {
                this.$store.commit('propinsis/setListPropinsi', value);
            }
        },
    },
    watch: {
        activePropinsi: {
            immediate: true,
            handler(value) {
                let vm = this;
                if (Object.keys(value).length === 0 && value.constructor === Object) return;
                vm.idori = value.id;
                vm.form.id = value.id;
                vm.form.nama = value.nama;
            }
        }
    },
    created() {
      const vm = this;
    },    
};
</script>