const propinsis = {
    namespaced: true,
    state: {
      // ACTIVE
      activePropinsi: [],
  
      // LIST
      listPropinsis: [],
  
      // SHOW
      showFormPropinsi: false,
    },
    getters: {
      getShowFormPropinsi: (state) => {
        return state.showFormPropinsi;
      },
      getActivePropinsi: (state) => {
        return state.activePropinsi;
      },
      getActivePropinsiManage: (state) => {
        return state.activePropinsi;
      },
      getListPropinsi: (state) => {
        return state.listPropinsi;
      },  
    },
    mutations: {
      //  SET ACTIVE
      setShowFormPropinsi(state, value) {
        state.showFormPropinsi = value;
      },
      setActivePropinsiManage(state, value) {
        state.activePropinsi = value;
      },  
      setListPropinsi(state, value) {
        state.listPropinsi = value;
      },  
     },
    actions: { }
  }
  
  export default propinsis;