<template>
    <div class="authincation h-100">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="col-md-6">
                    <div class="authincation-content">
                        <div class="row no-gutters">
                            <div class="col-xl-12">
                                <div class="auth-form">
									<div class="text-center mb-3">
										<a href="login"><img src="../assets/images/logo-full.png" alt=""></a>
									</div>
                                    <h4 class="text-center mb-4 text-white">Sign in your account</h4>
                                    <form @submit.prevent="submit">
                                        <div class="form-group">
                                            <label class="mb-1 text-white"><strong>UserName / Email</strong></label>
                                            <input type="text" class="form-control" v-model="payload.username">
                                        </div>
                                        <div class="form-group">
                                            <label class="mb-1 text-white"><strong>Password</strong></label>
                                            <input type="password" class="form-control" v-model="payload.password">
                                        </div>
                                        <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                            <div class="form-group">
                                               <div class="custom-control custom-checkbox ml-1 text-white">
													<input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
													<label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
												</div>
                                            </div>
                                            <div class="form-group">
                                                <a class="text-white" href="page-forgot-password.html">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn bg-white text-primary btn-block">Sign Me In</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2';
import { organisasi, propinsi } from '../helpers/loaddata';

export default {
    name: 'login',
    data() {
        return {
            payload : {
                username    : "",
                password    : ""
            }
        }
    },
    methods : {
      submit(){
        const config = {
          method: "POST",
          url: "/api/login",
          data : this.payload,
        }
        organisasi();
        propinsi();
        axiosKpapi(config)
        .then((response) => {
            localStorage.setItem("token", JSON.stringify(response.data.token));
            localStorage.setItem("menu", JSON.stringify(response.data.menu));
            localStorage.setItem("profile", JSON.stringify(response.data.profile));
            window.location.href = `/`;
        })
        .catch((error) => {
            Swal.fire({
                  icon: 'error',
                  title: 'Permission',
                  text: error.message,
            });
        });
      } 
    }   
}
</script>